import React from 'react';

import BlankPopup from './BlankPopup';
import OrderStatusPopup from './OrderStatusPopup';
import OrderRefundPopup from './OrderRefundPopup';


import { connect } from 'react-redux'
import { openPopup, closePopup } from '../../actions/PopupActions';
import './Popups.css';

class Popups extends React.Component {

  onClose() {
    this.props.closePopup(); 
  }

  render() {

    const popups = this.props.popups;
    const populated = popups.length > 0;

    return (
      <div className="Popups" data-populated={populated} onClick={(e)=>this.onClose(e)}>
        {popups.map((popup)=>{
          let props = popup.props || {};
          switch (popup.name) {
            case "OrderStatus":
              return <OrderStatusPopup key={`popup${popup.id}`} {...props}/>
            case "OrderRefund":
              return <OrderRefundPopup key={`popup${popup.id}`} {...props}/>
            case "Blank":
              return <BlankPopup key={`popup${popup.id}`} {...props}/>
            default:
              return <BlankPopup key={`popup${popup.id}`} {...props}/>
          }
        })}
      </div>
    );
  }
}

const mapState = (state, props) => {
  return {
    popups: state.popups
  }
};

const mapDispatch = (dispatch) => {
  return {
    openPopup: opts => dispatch(openPopup(opts)),
    closePopup: opts => dispatch(closePopup(opts))
  }
};

export default connect(
  mapState,
  mapDispatch
)(Popups)
