export const fetch2 = function(url, options={}) {
  console.log('fetch2 options: ', url, options)
  if(options.queryParams) {
    url += (url.indexOf('?') === -1 ? '?' : '&') + queryParams(options.queryParams);
    delete options.queryParams;
  }
  return fetch(url, options);
}

function queryParams(params) {
  let keys = Object.keys(params);
  let parts = [];
  keys.forEach((k)=>{
    if (params[k] !== undefined) {
      parts.push(encodeURIComponent(k) + '=' + encodeURIComponent(params[k]));
    }
  });
  return parts.join('&');
}
