import React from 'react';
import { connect } from 'react-redux'
import Menu from './Menu';
import './PixBoxMenu.css';
import MenuItemLayout from '../layouts/MenuItemLayout';
import { LEFT_MENU } from '../../constants/menus/LeftMenu';
import { expandMenu } from '../../actions/MenuActions';
import { openPopup } from '../../actions/PopupActions';
import PlainButton from '../elements/PlainButton';
import RectButton from '../elements/RectButton';
import DropControl from '../controls/DropControl';
import { dot } from '../../lib/obj';
import { logoutSession } from '../../actions/SessionActions';

class PixBoxMenu extends Menu {

  render() {
    return (
      <div className="PixBoxMenu" ref="root">
        <div className="Content">
          {this.props.children}
        </div>
      </div>
    );
  }
}

const mapState = (state, props) => {
  let menuName = props.name || "top";
  return {
    menuName: menuName,
    menu: state.menus[menuName] || {expanded:true},
    session: state.session
  }
};

const mapDispatch = (dispatch) => {
  return {
    expandMenu: opts => dispatch(expandMenu(opts)),
    openPopup: opts => dispatch(openPopup(opts)),
    logoutSession: opts => dispatch(logoutSession(opts))
  }
};

export default connect(
  mapState,
  mapDispatch
)(PixBoxMenu)
