/**
 * Returns the values of the given element
 */
export const grip = function(elem) {
  let data = {};
  switch (elem.nodeName) {
    case "P":
      data.text = elem.innerText;
      data.type = "p";
      break;
    default: {
      if (elem.classList.contains("Input")) {
        data = gripInput(elem);
        data.type = "Input";
      }
      else if (elem.classList.contains("TextArea")) {
        data = gripInput(elem);
        data.type = "TextArea";
      }
      else if (elem.classList.contains("SugControl")) {
        data = gripSugControl(elem);
        data.type = "SugControl";
      }
      else if (elem.classList.contains("RadioControl")) {
        data = gripRadioControl(elem);
        data.type = "RadioControl";
      }
      else if (elem.classList.contains("DropControl")) {
        data = gripDropControl(elem);
        data.type = "DropControl";
      }
      else if (elem.classList.contains("AddressControl")) {
        data = gripAddressControl(elem);
        data.type = "AddressControl";
      }
    }
  }
  return data;
}


/**
 * Returns the values of the given Input element
 */
export const gripInput = function(elem) {
  let data = {};
  if (!elem) {return data;}
  let label = elem.querySelector(".Label");
  if (label) { data.label = label.innerText}
  let field = elem.querySelector(".Field");
  if (field) {data.value = field.value};
  return data;
}

/**
 * Returns the values of the given SugSelector element
 */
export const gripSugControl = function(elem) {
  return gripInput(elem ? elem.querySelector(".Input") : null);
}

/**
 * Returns the values of the given RadioControl element
 */
export const gripRadioControl = function(elem) {
  let data = {};
  if (!elem) {return data;}
  let selected = elem.querySelector('.Option[data-selected="true"]') || elem.children[0];
  if (selected) { data.value = selected.innerText || selected };
  return data;
}

/**
 * Returns the values of the given DropControl element
 */
export const gripDropControl = function(elem) {
  let data = {};
  if (!elem) {return data;}
  let label = elem.querySelector(".Label");
  if (label) { data.label = label.innerText}
  let field = elem.querySelector(".Value");
  if (field) {data.value = field.innerText};
  return data;
}

/**
 * Returns the values of the given AddressControl element
 */
export const gripAddressControl = function(elem) {
  let data = {};
  if (!elem) {return data;}
  data.values = [];
  for (var i = 0; i < elem.children.length; i++) {
    let child = elem.children[i];
    data.values.push(grip(child));
  }
  return data;
}
