import React from 'react';
import Page from './Page'

import Separator from '../elements/Separator';
import NewOrderForm from '../forms/NewOrderForm';
import PageHeaderOutline from '../layouts/PageHeaderOutline';

import './Page.css';
import './NewOrderPage.css';

class NewOrderPage extends Page {
  render() {
    return (
      <div className="NewOrderPage Page">
        <PageHeaderOutline title="New Order" top={this._backButton()}></PageHeaderOutline>
        <p>Request help with a new order.</p>
        <NewOrderForm/>
      </div>
    );
  }
}

export default NewOrderPage;
