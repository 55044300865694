import React from 'react';
import { connect } from 'react-redux'
import OrderForm from '../forms/OrderForm'
import Input from '../elements/Input';
import TextArea from '../elements/TextArea';
import RectButton from '../elements/RectButton';
import FileControl from '../controls/FileControl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { trim, isValidEmail } from '../../lib/formats';
import { dot } from '../../lib/obj';

import '../forms/Form.css';
import './ECUnboxingSurvey.css';

class ECUnboxingSurvey extends OrderForm {

  validateFields() {
    console.log("validateFields")
    if (!super.validateFields()) { return false; }
    if (!this.checkQuestion("impressions")) { return false; }
    if (!this.checkQuestion("dissapointments")) { return false; }
    if (!this.checkQuestion("confusion")) { return false; }
    if (!this.checkQuestion("improvements")) { return false; }
    if (!this.checkQuestion("instructions")) { return false; }
    return true;
  }

  checkQuestion(name, message) {
    let ref = this.refs[name]
    if (ref && !trim(this.state[name])) {
      this.scrollToRef(ref);
      this.setState({
        errorField: name,
        shout: message || "Please enter a value."
      });
      return false;
    }
    return true;
  }

  ticketType() {
    return 'survey-ec-unboxing';
  }

  genQuestion(question, field, classPrefix = "Comment") {
    return <React.Fragment>
      <p className={classPrefix+"Label"}>{question}</p>
      <TextArea ref={field} className={classPrefix} label="Comments *" value={this.state[field]} error={this.state.errorField == field} lock={this.isSending()} onChange={(value)=>{this.onEditField(field,value)}}/>
    </React.Fragment>
  }

  render() {
    let state = this.state;

    // Check the ticket status
    if (state.ticketState == "requested") {
      return (
        <div className="Form">
          <p>Thank you for taking the time to complete our survey. Your feedback is incredibly valuable to us and helps us improve our products and services.</p>
          <p>We appreciate your insights and suggestions. If you have any further comments or need assistance, please don't hesitate to reach out to us at info@magbak.com.</p>
          <p>Thank you once again for your support.</p>
          <p>Cheers!<br/>The MagBak Team</p>
          <FontAwesomeIcon className="Icon" icon={["fal","check-circle"]} />
        </div>
      );
    }
    else {

      // Check if there is an error
      let error = state.errorField != null || state.ticketState == "error";

      // Check if sending ticket
      let sending = this.isSending();

      // Put refs on fields that are required
      return (
        <div ref="root" className="Form ECUnboxingSurvey">
          <Input ref="name" className="Name" label="Full Name *" value={this.state.name} error={state.errorField == 'name'} lock={sending} onChange={(value)=>{this.onEditField('name',value)}}/>
          <Input ref="email" className="Email" label="Email *" type="email" value={this.state.email}  error={state.errorField == 'email'} lock={sending} onChange={(value)=>{this.onEditField('email',value)}}/>
          {this.genQuestion("1. What things impressed you about the case?", "impressions", "Impressions")}
          {this.genQuestion("2. What things dissapointed you about the case?", "dissapointments", "Dissapointments")}
          {this.genQuestion("3. Was anything unclear about the packaging, instructions, or features? If so, please explain.", "confusion", "Confusion")}
          {this.genQuestion("4. How can we improve the initial experience?", "improvements", "Improvements")}
          {this.genQuestion("5. Did you read the instructions or watch the video? If not, what would encourage you to do so?", "instructions", "Instructions")}
          <p className="FilesLabel">Add images or videos (20MB limit per file)</p>
          <FileControl className="Files" onItemsChanged={(items)=>{this.onFileItemsChanged(items)}}/>

          <RectButton className="Submit" theme="darkblue" working={sending} onClick={(evt)=>{this.onSubmit(evt)}}>{sending ? "WORKING" : "SUBMIT"}</RectButton>
          <p className="Shout" data-error={error}>{state.shout || "* Fields are required."}</p>
        </div>
      );
    }

  }
}

export default ECUnboxingSurvey;
