import React from 'react';
import { connect } from 'react-redux'
import OrderForm from './OrderForm'
import Input from '../elements/Input';
import TextArea from '../elements/TextArea';
import RectButton from '../elements/RectButton';
import DropControl from '../controls/DropControl';
import RadioControl from '../controls/RadioControl';
import MultiControl from '../controls/MultiControl';
import FileControl from '../controls/FileControl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { trim } from '../../lib/formats';
import { dot } from '../../lib/obj';

import './Form.css';
import './RefundOrderForm.css';

import { SHIP_METHODS } from '../../constants/misc/ShipMethods';

const RETURN_REASONS = [
  {
    name: "Ordered wrong product",
  },
  {
    name: "Placed a duplicate order",
  },
  {
    name: "Received a defective product",
  },
  {
    name: "Dissatisfied with purchase",
  },
  {
    name: "Unhappy with functionality",
  },
  {
    name: "Other",
  }
];


class RefundOrderForm extends OrderForm {

  ticketType() {
    return 'order-refund';
  }

  onFileItemsChanged(items) {
    let files = [];
    if (items) {
      items.forEach((item)=>{
        if (item.status == "loaded") {
          files.push({loc:item.loc});
        }
      });
    }
    this.setState({files:files});
  }

  render() {
    let state = this.state;
    let orderData = this.props.orderData;

    // Check the ticket status
    if (state.ticketState == "requested") {
      return (
        <div className="Form">
          <p>Thank you for contacting the MagBak Support Team!</p>
          <p>We have received your inquiry and will be getting back to you very soon.</p>
          <p>Our support hours are M-F 9AM-5PM US Pacific Time.</p>
          <p>We appreciate you choosing MagBak and look forward to resolving any concerns.</p>
          <p>Cheers!<br/>The MagBak Team</p>
          <FontAwesomeIcon className="Icon" icon={["fal","check-circle"]} />
        </div>
      );
    }
    else {

      // Check if there is an error
      let error = state.errorField != null || state.ticketState == "error";

      // Check if sending ticket
      let sending = this.isSending();

      let infoClass = "OrderInfo";
      let items = [];
      if (orderData) {
        orderData.items.forEach((item, i) => {
          for (var i = 0; i < item.quantity; i++) {
            items.push({name:item.name, sku: item.sku});
          }
        });
      }
      else {
        infoClass = "NoOrderInfo"
      }

      // Put refs on fields that are required
      return (
        <div ref="root" className={`Form RefundOrderForm ${infoClass}`}>
          <p className="Intro">Refunds can only be requested within 30 days of product delivery.</p>
          <Input ref="name" className="Name" label="Full Name *" value={this.state.name} error={state.errorField == 'name'} lock={sending} onChange={(value)=>{this.onEditField('name',value)}}/>
          <Input ref="email" className="Email" label="Email *" type="email" value={this.state.email}  error={state.errorField == 'email'} lock={sending} onChange={(value)=>{this.onEditField('email',value)}}/>
          <Input ref="order" className="Order" label="Order # *" value={this.state.order} lock={sending} error={state.errorField == 'order'} onChange={(value)=>{this.onEditField('order',value)}}/>
          <p className="ItemsLabel">Please select the products to refund:</p>
          <MultiControl className="Items" onChange={(index,cps)=>this.onEditField('returnItems',cps.map((c)=>c.value).join(", "))}>
            {items.map((item)=>{
              return <span value={item.sku}>{item.name}</span>
            })}
          </MultiControl>
          <p className="Desc">We are always looking to improve our products. If you have any feedback please let us know :)</p>
          <DropControl className="Reason" label="Reason" options={RETURN_REASONS} value={this.state.reason} lock={sending} error={state.errorField == 'reason'} onOptionClick={(option)=>{this.onEditField('reason',option.name)}}/>
          <TextArea ref="comment" className="Comment" label="Comment *" value={this.state.comment} lock={sending} error={state.errorField == 'comment'} onChange={(value)=>{this.onEditField('comment',value)}}/>

          <p className="Attach">Add a picture or video. (25MB limit)</p>
          <FileControl className="Files" onItemsChanged={(items)=>{this.onFileItemsChanged(items)}}/>
        
          <p className="Label">Would you like us to provide you with a shipping label?</p>
          <RadioControl className="LabelResponse" onChange={(index,cps)=>this.onEditField('shipLabel',cps.value)}>
            <span value="Customer provided">No - I'll get my own label</span>
            <span value="MagBak provided">Yes - US orders only <i>(Additional fees may apply)</i></span>
          </RadioControl>
          <RectButton className="Submit" theme="darkblue" working={sending} onClick={(evt)=>{this.onSubmit(evt)}}>{sending ? "WORKING" : "SUBMIT"}</RectButton>
          <p className="Shout" data-error={error}>{state.shout || "* Fields are required."}</p>
        </div>
      );
    }

  }
}

export default RefundOrderForm;
