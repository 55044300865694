import React from 'react';
import Page from './Page'

import Separator from '../elements/Separator';
import UpdateOrderForm from '../forms/UpdateOrderForm';
import PageHeaderOutline from '../layouts/PageHeaderOutline';

import './Page.css';
import './UpdateOrderPage.css';

class UpdateOrderPage extends Page {
  render() {
    return (
      <div className="UpdateOrderPage Page">
        <PageHeaderOutline title="Order Edits" top={this._backButton()}></PageHeaderOutline>
        <p>Request to edit products, quantities, shipping, etc.</p>
        <UpdateOrderForm/>
      </div>
    );
  }
}

export default UpdateOrderPage;
