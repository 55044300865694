import React from 'react';
import { connect } from 'react-redux';

import Page from './Page'
import OrderForm from '../forms/OrderForm';
import PageHeaderOutline from '../layouts/PageHeaderOutline';

import { dot } from '../../lib/obj';
import { getOrderStatus } from '../../actions/SupportActions.js';
import { openPopup } from '../../actions/PopupActions.js';

import './Page.css';
import './OrderStatusRequestPage.css';

class OrderStatusRequestPage extends Page {

  render() {
    let form = this.props.form;
    return (
      <div className="OrderStatusRequestPage Page">
        <PageHeaderOutline title="Order Status" top={this._backButton()}></PageHeaderOutline>
        <p>Request order activity and shipping progress.</p>
        <OrderForm email={dot(form,"data.email")} order={dot(form,"data.order")}/>
      </div>
    );
  }
}

const mapState = (state, props) => {
  return {
    form: dot(state, "forms.status")
  }
};

const mapDispatch = (dispatch) => {
  return {
    getOrderStatus: opts => dispatch(getOrderStatus(opts)),
    openPopup: opts => dispatch(openPopup(opts))
  }
};

export default connect(
  mapState,
  mapDispatch
)(OrderStatusRequestPage)
