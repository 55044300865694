import React from 'react';
import { connect } from 'react-redux'
import Menu from './Menu';
import './LeftMenu.css';
import MenuItemLayout from '../layouts/MenuItemLayout';
import { LEFT_MENU } from '../../constants/menus/LeftMenu'
import { collapseMenu } from '../../actions/MenuActions'
import PlainButton from '../elements/PlainButton';

class LeftMenu extends Menu {

  componentWillMount() {
    //this.props.collapseMenu({name:"left"});
  }

  onClose() {
    this.props.collapseMenu({name:"left"});
  }

  render() {
    // Handle to the menu
    const menu = this.props.menu;
    return (
      <div className="LeftMenu" ref="root" data-expanded={menu.expanded} onClick={()=>{this.onClose()}}>
        <div className="Content" onClick={(evt)=>{evt.stopPropagation();}}>
          <div className="Controls">
            <PlainButton className="Close" onClick={()=>{this.onClose()}}>&times;</PlainButton>
          </div>
          <div className="MenuTop">
          </div>
          <div className="MenuMiddle">
            {LEFT_MENU.map((item, index)=>{
              return <MenuItemLayout key={`item${index}`} activePath={this.props.activePath} onNavigate={()=>{this.onClose()}} {...item} />
            })}
          </div>
          <div className="MenuBottom">
          </div>
        </div>
      </div>
    );
  }
}

const mapState = (state, props) => {
  let menuName = props.name || "left";
  return {
    menuName: menuName,
    menu: state.menus[menuName] || {expanded:false},
    activePath: window.location.pathname
  }
};

const mapDispatch = (dispatch) => {
  return {
    collapseMenu: opts => dispatch(collapseMenu(opts))
  }
};

export default connect(
  mapState,
  mapDispatch
)(LeftMenu)
