import React from 'react';
import Layout from './Layout';
import './FAQLayout.css';

import Row from '../elements/Row';
import Readout from '../elements/Readout';
import RoundButton from '../elements/RoundButton';
import { dot } from '../../lib/obj';
import { financial } from '../../lib/formats';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class FAQLayout extends Layout {

  anchorClicked = event => {
    alert("I was clicked");
  }

  componentDidMount() {
    if (this.props.question) {
      setTimeout(()=>{
        this.gotoQuestion(null,`#${this.props.question}`,{behavior:'auto'});
      },250);
    }
  }

  // Handle when goto a question
  gotoQuestion(evt, url, {behavior='smooth'} = {}) {
    if (url && url.match(/^#/)) {
      let elem = document.querySelector(url);
      if (!elem) { return; }
      const y = elem.getBoundingClientRect().top + window.scrollY  - 90;
      window.scroll({
        top: y,
        behavior: behavior
      });
      if (evt) { evt.preventDefault(); }
      elem.dataset.highlight = "true";
      setTimeout(()=>{ elem.dataset.highlight = "false"; },5000)
    }
  }

  goBack(evt, qid, cid) {
    if (cid) {
      let elem = document.querySelector(`#${cid}`);
      if (!elem) { return; }
      const y = elem.getBoundingClientRect().top + window.scrollY  - 90;
      window.scroll({
        top: y,
        behavior: "smooth"
      });
      if (evt) { evt.preventDefault(); }
      setTimeout(()=>{
        elem.dataset["collapsed"] = false;
      },1000)
    }
    else {
      window.scroll({
        top: 0,
        behavior: 'smooth'
      });
    }
  }

  toggleCategory(evt, index) {
    let faq = this.props.faq || {};
    let categories = [...faq.categories];
    let category = categories[index];
    category = {...category};
    category.collapsed = !category.collapsed;
    categories[index] = category;
    faq.categories = categories;
    this.setState({faq:faq});
  }

  copyToClipboard(evt, id) {
    if (id) {
      var range = document.createRange();
      let elem = document.querySelector(`#${id} > .Answer `);
      console.log("Elem: ", elem);
      range.selectNode(elem);
      window.getSelection().removeAllRanges(); // clear current selection
      window.getSelection().addRange(range); // to select text
      document.execCommand("copy");
      window.getSelection().removeAllRanges();// to deselect
    }
  }

  copyLinkToClipboard(evt,qid) {
    if (qid) {
      navigator.clipboard.writeText(`${window.location.host}/faq?question=${qid}`).then(function() {
        /* clipboard successfully set */
      }, function() {
        /* clipboard write failed */
      });
    }
  }

  onAnswerClick(evt) {
    this.gotoQuestion(evt,evt.target.getAttribute("href"));
  }

  render() {
    let faq = this.props.faq || {};
    let categories = faq.categories || [];
    return (
      <div className="FAQLayout" ref="root">
        <div className="Preview">
          {categories.map((category, index)=>{
            let cid = category.url ? category.url.replace(/^#/,'') : category.url;
            return <div key={`category${index}`} id={cid} className="Category" data-collapsed={category.collapsed}>
              <div className="Title" onClick={(evt)=>{this.toggleCategory(evt,index)}}>
                {category.title}
                <div className="Control">&#x276E;</div>
              </div>
              <div className="Questions">
                {(category.questions || []).map((question, qi)=>{
                  let qid = question.url ? question.url.replace(/^#/,'') : question.url;
                  return <a key={`question${qi}`} data-id={qid} className="Question" href={question.url} onClick={(evt)=>{this.gotoQuestion(evt, question.url)}}>
                    {qi+1}.&nbsp;{question.title}
                  </a>
                })}
              </div>
            </div>
          })}
        </div>
        <div className="Categories">
          {categories.map((category, ci)=>{
            return <div key={`category${ci}`} className="Category">
              <div className="Title">{category.title}</div>
              <div className="Questions">
                {(category.questions || []).map((question, qi)=>{
                  let qid = question.url ? question.url.replace(/^#/,'') : question.url;
                  let cid = category.url ? category.url.replace(/^#/,'') : category.url;
                  return <div id={qid} key={`question${qi}`} className="Question">
                    <div className="Title">{question.title}&nbsp;</div>
                    <div className="Answer" dangerouslySetInnerHTML={{__html:question.answer}} onClick={(evt)=>this.onAnswerClick(evt)}></div>
                    <div className="Controls">
                      <span className="Copy" onClick={(evt)=>{this.copyLinkToClipboard(evt,qid)}}><FontAwesomeIcon icon={["fal","link"]} /></span>
                      <span className="Copy" onClick={(evt)=>{this.copyToClipboard(evt,qid)}}><FontAwesomeIcon icon={["fal","copy"]} /></span>
                      <span className="Back" onClick={(evt)=>{this.goBack(evt,qid,cid)}}><FontAwesomeIcon icon={["fal","arrow-to-top"]} /></span>
                    </div>
                  </div>
                })}
              </div>
            </div>
          })}
        </div>
      </div>
    );
  }
}

export default FAQLayout;
