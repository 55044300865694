import React from 'react';
import { withRouter } from 'react-router'
import Layout from './Layout';
import './PixBoxLayout.css';

import Row from '../elements/Row';
import Readout from '../elements/Readout';
import RoundButton from '../elements/RoundButton';
import { dot } from '../../lib/obj';
import { financial } from '../../lib/formats';



class PixBoxLayout extends Layout {

  onClick(evt) {
    if (this.props.onClick) {
      this.props.onClick(evt, this.props.history);
    }
  }

  render() {
    return (
      <div className="PixBoxLayout" ref="root" onClick={(evt)=>{this.onClick(evt)}}>
        {this.props.children}
      </div>
    );
  }
}

const RoutedPixBoxLayout = withRouter(PixBoxLayout)
export default RoutedPixBoxLayout;
