import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Page from './Page'

import Separator from '../elements/Separator';
import OrderStatusForm from '../forms/OrderStatusForm';
import PageHeaderOutline from '../layouts/PageHeaderOutline';

import { getOrderStatus } from '../../actions/SupportActions.js';
import { openPopup, closePopup } from '../../actions/PopupActions.js';
import { dot } from '../../lib/obj';

import './Page.css';
import './RefundOrderPage.css';

class RefundOrderPage extends Page {

  onStatusResponse() {
    let data = this.props.form.data;
    this.props.openPopup({
      name:"OrderRefund",
      props: {
        order:data.order,
        onOk:(evt, history)=>{
          this.props.closePopup();
        },
        onRefund:(evt, history)=>{
          this.props.closePopup();
          history.push(`/rma/refund-request`);
        }
      }
    });
  }

  render() {
    return (
      <div className="RefundOrderPage Page">
        <PageHeaderOutline title="Refund Request" top={this._backButton()}></PageHeaderOutline>
        <p>Check if your order is eligible for a refund.</p>
        <OrderStatusForm note="Don't know your order number? You can find your order number in your order confirmation email." onStatusResponse={()=>{this.onStatusResponse()}}/>
      </div>
    );
  }
}

const mapState = (state, props) => {
  let orderNumber = dot(state,"forms.status.data.order");
  return {
    form: dot(state,"forms.status") || {},
    order: dot(state,["support","status","orders",orderNumber]) || {}
  }
};

const mapDispatch = (dispatch) => {
  return {
    getOrderStatus: opts => dispatch(getOrderStatus(opts)),
    openPopup: opts => dispatch(openPopup(opts)),
    closePopup: opts => dispatch(closePopup(opts))
  }
};

export default connect(
  mapState,
  mapDispatch
)(RefundOrderPage)
