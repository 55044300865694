import * as ACTIONS from './ActionTypes';

export const startForm = ({name="form", props={}, data=undefined, params=undefined, force=false}={}) => {
  return {
    type: ACTIONS.START_FORM,
    name: name,
    data: data,
    params: params,
    force: force
  }
}

export const clearForm = ({name=null}={}) => {
  return {
    type: ACTIONS.CLEAR_FORM,
    name: name
  }
}

export const editForm = ({name=null, field=null, value=null}={}) => {
  return {
    type: ACTIONS.EDIT_FORM,
    name: name,
    field: field,
    value: value
  }
}

export const configForm = ({name=null, params=null}={}) => {
  return {
    type: ACTIONS.CONFIG_FORM,
    name: name,
    params: params
  }
}
