import React from 'react';
import Element from './Element';
import './TextArea.css';

class TextArea extends Element {

  constructor(props) {
    super(props);
    this.state = {
      focused:false,
      filled:false,
      value: this.props.value
    };
  }

  onFocus(evt) {
    this.setState({focused:true});
    if (this.props.onFocus) {
      this.props.onFocus(evt);
    }
  }

  onBlur(evt) {
    this.setState({focused:false});
    if (this.props.onBlur) {
      this.props.onBlur(evt);
    }
  }

  onChange(evt) {
    // Update State
    this.setState({
      value: evt.target.value,
      filled: evt.target.value.length > 0
    });

    // Check for onChange callback
    if (this.props.onChange) {
      this.props.onChange(evt.target.value);
    }
  }

  render() {

    // Check for label
    let label = <div/>
    if (this.props.label) {
      label = <div className="Label">{this.props.label}</div>
    }

    // Get the value
    let value = this.state.focused ? undefined : (this.props.value === undefined ? '' : this.props.value);

    let className = this.props.className || "";
    return (
      <div className={`TextArea ${className}`} ref="root"
        data-focused={this.state.focused}
        data-filled={this.state.filled}
        data-lock={this.props.lock}
        data-error={this.props.error}>
        {label}
        <textarea className="Field" ref="input"
          onFocus={(evt)=>this.onFocus(evt)}
          onBlur={(evt)=>this.onBlur(evt)}
          onChange={(evt)=>this.onChange(evt)}
          type={this.props.type || "text"}
          autoComplete="off"
          value={value}
          />
      </div>
    );
  }
}

export default TextArea;
