import React from 'react';
import Page from './Page'

import SalesForm from '../forms/SalesForm';
import PageHeaderOutline from '../layouts/PageHeaderOutline';

import './Page.css';
import './SalesPage.css';

class SalesPage extends Page {
  render() {
    return (
      <div className="SalesPage Page">
        <PageHeaderOutline title="Become A Distributor" top={this._backButton()}></PageHeaderOutline>
        <p>B2B, distribution, resale partners, and bulk orders.</p>
        <SalesForm/>
      </div>
    );
  }
}

export default SalesPage
