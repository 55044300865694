import React from 'react';
import { withRouter } from 'react-router'
import Layout from './Layout';
import './MenuItemLayout.css';

import { dot } from '../../lib/obj';

class MenuItemLayout extends Layout {
  constructor(props) {
    super(props);
    // Set the initial state
    this.state = {
      expanded: this.props.expanded == "true" ? true : false
    };
  }

  onClick(history) {
    if (this.props.url) {
      if (this.props.onNavigate) {
        this.props.onNavigate();
      }
      this.props.history.push(this.props.url);
    } else {
      this.setState({expanded:!this.state.expanded})
    }
  }

  render() {
    let sub = <div/>;
    let signal = <div/>;
    if (this.props.items) {
      sub = <div className="Nested">
        {this.props.items.map((item, index)=>{
          return <RoutedMenuItemLayout activePath={this.props.activePath} key={`item${index}`} onNavigate={this.props.onNavigate} {...item}/>
        })}
      </div>
      signal = <div className="Signal">&#10095;</div>
    }

    // Check if is active
    //let isActive = this.props.activePath && this.props.url === this.props.activePath;
    let isActive = this.props.activePath && (this.props.url === this.props.activePath || this.props.activePath.indexOf(this.props.url+"/") === 0);
    return (
      <div className="MenuItemLayout" ref="root" data-expanded={this.state.expanded} data-active={isActive}>
        <button className="Link" onClick={(history)=>{this.onClick(history)}}>
          <div className="Icon">{this.props.icon}</div>
          <div className="Label">{this.props.label}</div>
          {signal}
        </button>
        {sub}
      </div>
    );
  }
}

const RoutedMenuItemLayout = withRouter(MenuItemLayout)
export default RoutedMenuItemLayout;
