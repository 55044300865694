import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux'
import OrderForm from './OrderForm'
import Input from '../elements/Input';
import TextArea from '../elements/TextArea';
import RectButton from '../elements/RectButton';
import DropControl from '../controls/DropControl';
import SugControl from '../controls/SugControl';
import FileControl from '../controls/FileControl';
import AddressControl from '../controls/AddressControl';
import RadioControl from '../controls/RadioControl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { trim } from '../../lib/formats';
import { dot } from '../../lib/obj';
import { grip } from '../../lib/elem';
import { searchMatch} from '../../lib/search';

import './Form.css';
import './ReplaceOrderForm.css';

import { PRODUCT_INDEX, VARIANT_MAP } from '../../constants/support/Products';
import { SHIP_METHODS } from '../../constants/misc/ShipMethods';

const ADDRESS_LABEL_ALTS = {
  "Address": "Street1"
};


class ReplaceOrderForm extends OrderForm {

  constructor(props) {
    super(props);
    this.state.sameAddress = "yes";
  }

  ticketType() {
    return 'order-replace';
  }

  onFileItemsChanged(items) {
    let files = [];
    if (items) {
      items.forEach((item)=>{
        if (item.status == "loaded") {
          files.push({loc:item.loc});
        }
      });
    }
    this.setState({files:files});
  }

  onSuggestProduct(options, value) {
    let sugs = [];
    if (value && value.length > 0) {
      for (var i = 0; i < PRODUCT_INDEX.length; i++) {
        let prod = PRODUCT_INDEX[i];
        if (prod.options) {
          let kwds = prod.keywords.join(" ");
          prod.options.forEach((o)=>{
            let m = searchMatch(value,o+" "+kwds);
            if (m) { sugs.push(o); }
          });
        }
      }
    }
    return sugs;
  }

  onAddressEditField(field, value) {
    if (this.refs.address) {
      setTimeout(()=>{
        let lines = [];
        let d = grip(ReactDOM.findDOMNode(this.refs.address));
        d.values.forEach((v, i) => {
          let label = v.label ? (ADDRESS_LABEL_ALTS[v.label] || v.label) : v.label;
          lines.push(`- ${label ? label+" " : ""}[ ${v.value} ]`);
        });
        this.onEditField('shipTo',lines.join("\n"));
      },100);
    }
  }

  onEditProduct(val) {
    let state = this.state;
    if (state.method) {
      this.onEditField("method","");
    }
    this.onEditField("product",val);
  }

  render() {
    let state = this.state;

    // Check the ticket status
    if (state.ticketState == "requested") {
      return (
        <div className="Form">
          <p>Thank you for contacting the MagBak Support Team!</p>
          <p>We have received your inquiry and will be getting back to you very soon.</p>
          <p>Our support hours are M-F 9AM-5PM US Pacific Time.</p>
          <p>We appreciate you choosing MagBak and look forward to resolving any concerns.</p>
          <p>Cheers!<br/>The MagBak Team</p>
          <FontAwesomeIcon className="Icon" icon={["fal","check-circle"]} />
        </div>
      );
    }
    else {

      // Check if there is an error
      let error = state.errorField != null || state.ticketState == "error";

      // Check if sending ticket
      let sending = this.isSending();

      // Get the ship methods based on the selected product
      let shipMethods = dot(VARIANT_MAP,[state.product,"shipMethods"]) || SHIP_METHODS;

      // Put refs on fields that are required
      return (
        <div ref="root" className="Form ReplaceOrderForm" data-same-address={state.sameAddress}>
          <p className="Intro">Replacements can only be requested within the product warranty.</p>
          <Input ref="name" className="Name" label="Full Name *" value={this.state.name} error={state.errorField == 'name'} lock={sending} onChange={(value)=>{this.onEditField('name',value)}}/>
          <Input ref="email" className="Email" label="Email *" type="email" value={this.state.email}  error={state.errorField == 'email'} lock={sending} onChange={(value)=>{this.onEditField('email',value)}}/>
          <Input ref="order" className="Order" label="Order # *" value={this.state.order} lock={sending} error={state.errorField == 'order'} onChange={(value)=>{this.onEditField('order',value)}}/>

          <p className="Specify">Which product would you like to replace?</p>
          <SugControl ref="product" className="Product" label="Product *" error={state.errorField == 'product'} lock={sending} onSuggest={(options, value)=>this.onSuggestProduct(options,value)} onChange={(value)=>{this.onEditProduct(value)}}/>

          <p className="Desc">Please describe why you want to replace the product. Attach any files to help us better understand your issue.</p>
          <TextArea ref="comment" className="Comment" label="Comment *" value={this.state.comment} lock={sending} error={state.errorField == 'comment'} onChange={(value)=>{this.onEditField('comment',value)}}/>
        
          <p className="Attach">Add a picture or video. (25MB limit)</p>
          <FileControl className="Files" onItemsChanged={(items)=>{this.onFileItemsChanged(items)}}/>

          <p className="Where">Ship replacement product to the same shipping address as the original order?</p>
          <RadioControl className="WhereResponse" onChange={(index,cps)=>this.onEditField('sameAddress',cps.value)}>
            <span value="yes">Yes</span>
            <span value="no">No, ship to this address</span>
          </RadioControl>
          <AddressControl ref="address" ignore={state.sameAddress != "no"} className="Address" label="Address" value={this.state.address} lock={sending} onEditField={(name,value)=>{this.onAddressEditField(name,value)}}/>

          <p className="Ship">How would you like us to ship your replacement?</p>
          <DropControl ref="method" className="Method" label="Shipping Method *" options={shipMethods} value={this.state.method} lock={sending} error={state.errorField == 'method'} onOptionClick={(option)=>{this.onEditField('method',option.name)}}/>

          <RectButton className="Submit" theme="darkblue" working={sending} onClick={(evt)=>{this.onSubmit(evt)}}>{sending ? "WORKING" : "SUBMIT"}</RectButton>
          <p className="Shout" data-error={error}>{state.shout || "* Fields are required."}</p>
        </div>
      );
    }

  }
}

export default ReplaceOrderForm;
