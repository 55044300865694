import * as ACTIONS from '../actions/ActionTypes';
import { elements } from './inventory/ElementsReducer.js'
import { pos } from './inventory/POsReducer.js'
import { dot, reg } from '../lib/obj.js'
import { createReducer } from 'redux-starter-kit'
import Draft from '../lib/draft.js'

// Maps the plural words to singular versions
const SINGULAR = {
  pos: "po",
  items: "item"
}

// Maps the child types to singular versions
const CHILD_SINGULAR = {
  pos: {
    items: "poItem"
  }
}

// Maps the child types to singular versions
const CHILD_PLURAL = {
  pos: {
    items: "poItems"
  }
}

// Maps the child types to singular versions
const DOC_KEY_OPTS = {
  action: { type: "actions" },
  actions: { type: "actions" },
  file: { type: "files" },
  files: { type: "files" },
  location: { type: "locations" },
  locations: { type: "locations" },
  login: { type: "logins" },
  logins: { type: "logins" },
  order: { type: "orders" },
  orders: { type: "orders" },
  orderItem: { type: "orderItems" },
  orderItems: { type: "orderItems" },
  po: { type: "pos" },
  pos: { type: "pos" },
  poItem: { type: "pos", childType: "items" },
  poItems: { type: "pos", childType: "items" },
  product: { type: "products" },
  products: { type: "products" },
  productPart: { type: "products", childType: "parts" },
  productParts: { type: "products", childType: "parts" },
  transfer: { type: "transfers" },
  transfers: { type: "transfers" },
  vendor: { type: "vendors" },
  vendors: { type: "vendors" }
}

/*
 * Helps get the name item map
**/
const namify = function(group, child) {
  if (child !== undefined) {
    return dot(CHILD_PLURAL, [group,child]);
  }
  return SINGULAR[group];
};

/*
 * Helps get the name item map
**/
const plurafy = function(group, child) {
  if (child !== undefined) {
    return dot(CHILD_SINGULAR, [group,child]);
  }
  return group
};

/**
 * Handles when an item is purged
**/
const purgeItem = function (state, action) {
  let opts = action.opts;
  if (opts.type) {
    let group = dot(state, opts.type);
    let groupItem = dot(group,opts.id);
    if (groupItem && opts.id !== undefined) {
      // Attempt to delete child item
      if (opts.childType) {
        if (opts.index !== undefined) {
          let docs = dot(groupItem,[opts.childType,"list","docs"]);
          if (docs) {
            docs.splice(opts.index, 1)
          }
        }
        else {
          let childGroup = dot(groupItem, opts.childType);
          if (childGroup && opts.childId !== undefined) {
            delete childGroup[opts.childId];
          }
        }
      }
      // Delete the group item
      else {
        delete group[opts.id];
      }
    };
  }
};

/**
 * Handles when an item is sent to be created
**/
const createItem = function (state, action) {
  let opts = action.opts;
  let docState = stateForDoc(state, opts);
  if (docState) {
    docState.status = "creating"
  }
};

/**
 * Handles when an item is created
**/
const createItemSuccess = function (state, action) {
  placeDocs(state, action, "success");
};

/**
 * Handles when an item is sent to be saved
**/
const saveItem = function (state, action) {
  let opts = action.opts;
  let docState = stateForDoc(state, opts);
  if (docState) {
    docState.status = "saving"
  }
};

/**
 * Handles when an item is saved
**/
const saveItemSuccess = function (state, action) {
  placeDocs(state, action, "success");
};

/**
 * Handles when an item is going to be loaded
**/
const loadItem = function (state, action) {
  let opts = action.opts;
  let docState = stateForDoc(state, opts);
  if (docState) {
    docState.status = "loading"
  }
};

/**
 * Handles when an item is loaded
**/
const loadItemSuccess = function (state, action) {
  placeDocs(state, action, "success");
};


/**
 * Handles when an items are requested
**/
const listItems = function (state, action) {
  let opts = action.opts || {};
  let list = stateForDocList(state, opts);
  list.status = "loading";
};

/**
 * Handles when an items are loaded
**/
const listItemsSuccess = function (state, action) {
  let docs = action.docs || {};
  let opts = action.opts || {};
  Object.keys(docs).forEach((key)=>{
    let doc = docs[key];
    if (Array.isArray(doc)) {
      if (key === opts.resType) {
        let listState = stateForDocList(state, opts);
        listState.status = "success";
        dot(listState,"docs",doc.map((singleDoc)=>{
          return {
            data: singleDoc.data,
            docs: singleDoc.docs,
            role: singleDoc.role
          }
        }));
      }
      else {
        doc.forEach((singleDoc)=>{
          let docOpts = optsForDoc(singleDoc, key);
          placeDoc(state, singleDoc, docOpts);
        });
      }
    }
    else {
      let docOpts = optsForDoc(doc, key);
      if (docOpts) {
        placeDoc(state, doc, docOpts);
      }
    }
  });
};

/**
 * Helps save the item into its given designated group placement
**/
const placeDocs = function(state, action, status) {
  let docs = action.docs || {};
  let opts = action.opts || {};
  Object.keys(docs).forEach((key)=>{
    let doc = docs[key];
    if (Array.isArray(doc)) {
      doc.forEach((singleDoc)=>{
        let docOpts = optsForDoc(singleDoc, key);
        placeDoc(state, singleDoc, docOpts, status);
      });
    }
    else {
      let docOpts = optsForDoc(doc, key);
      if (docOpts) {
        docOpts.isMain = key == opts.resType;
        placeDoc(state, doc, docOpts, status);
      }
    }
  });
};

/**
 * Helps place a single doc
**/
const placeDoc = function(state, doc, opts, status) {
  if (opts) {
    let docState = stateForDoc(state, opts);
    if (opts.isMain) {
      dot(docState,"status",status);
      dot(docState,"data", doc.data);
      dot(docState,"docs", doc.docs);
      dot(docState,"role", doc.role);
    }
    else {
      reg(docState,"status",status);
      reg(docState,"data", doc.data);
      reg(docState,"docs", doc.docs);
      reg(docState,"role", doc.role);
    }
  }
};


/**
 * Helps get the full opts for the given doc
**/
const optsForDoc = function(doc, key) {
  let opts = DOC_KEY_OPTS[key];
  if (opts) {
    let docOpts = {
      id: dot(doc,"data.id"),
      type: opts.type
    };
    if (opts.childType) {
      docOpts.childType = opts.childType;
      docOpts.childId = dot(doc,"data.id");
    }
    return docOpts;
  }
  return undefined;
}

/**
 * Gets the nested state the represents the doc for the given options
**/
const stateForDoc = function (state, opts) {
  let type = opts.typeAlias || opts.type;
  if (type) {
    let group = reg(state, type, {});
    let id = opts.idAlias || opts.id;
    let groupItem = reg(group,[id],{});
    let childType = opts.childTypeAlias || opts.childType;
    if (childType) {
      if (opts.index !== undefined) {
        let docs = reg(groupItem,[childType,"list","docs"],[]);
        return reg(docs,opts.index,{});
      }
      else {
        let childId = opts.childIdAlias || opts.childId;
        return reg(groupItem,[childType, childId],{});
      }
    }
    else {
      return groupItem;
    }
  }
};

/**
 * Gets the nested state the represents the list of documents
**/
const stateForDocList = function (state, opts) {
  let type = opts.typeAlias || opts.type;
  if (type) {
    let group = reg(state, type, {});
    let id = opts.idAlias || opts.id;
    let childType = opts.childTypeAlias || opts.childType;
    if (id && childType) {
      let groupItem = reg(group,id,{});
      return reg(groupItem,[childType,"list"],{});
    }
    else {
      return reg(group,"list",{});
    }
  }
};

/**
 * Gets the nested state the represents the list of documents
**/
const setItemField = function(state, action) {
  let opts = action.opts;
  let docState = stateForDoc(state, opts) || {};
  let draft = new Draft(docState.data, docState.sets, docState.incs, docState.clears);

  // if (opts.value === "") {
  //   draft.clear(opts.field);
  // }
  // else {
  //   draft.val(opts.field, opts.value);
  // }
  draft.val(opts.field, opts.value);

  docState.data = draft.data;
  docState.docs = draft.docs;
  docState.sets = draft.sets;
  docState.clears = draft.clears;
}

/**
 * Starts a draft item (will register it)
**/
const startDraftItem = function(state, action) {
  let opts = action.opts || {};
  let docState = stateForDoc(state, opts);
  reg(docState,'data',opts.data);
}

/**
 * Starts a draft item (will register it)
**/
const setItemAction = function(state, action) {
  let opts = action.opts || {};
  let docState = stateForDoc(state, opts);
  dot(docState,'action',opts.action);
}

/**
 * Sets or updates an item query
**/
const setItemQuery = function(state, action) {
  let opts = action.opts || {};
  let list = stateForDocList(state, opts);
  list.query = opts.query;
}

/*
 * Main inventory reducer
 * NOTE: Users Immer to more easily handle immutable state updates
 * Ref: https://redux-starter-kit.js.org/api/createreducer
**/
export const inventory = createReducer({}, {
  [ACTIONS.PURGE_ITEM]: purgeItem,
  [ACTIONS.CREATE_ITEM]: createItem,
  [ACTIONS.CREATE_ITEM_SUCCESS]: createItemSuccess,
  [ACTIONS.SAVE_ITEM]: saveItem,
  [ACTIONS.SAVE_ITEM_SUCCESS]: saveItemSuccess,
  [ACTIONS.LOAD_ITEM]: loadItem,
  [ACTIONS.LOAD_ITEM_SUCCESS]: loadItemSuccess,
  [ACTIONS.LIST_ITEMS]: listItems,
  [ACTIONS.LIST_ITEMS_SUCCESS]: listItemsSuccess,
  [ACTIONS.SET_ITEM_FIELD]: setItemField,
  [ACTIONS.START_DRAFT_ITEM]: startDraftItem,
  [ACTIONS.SET_ITEM_ACTION]: setItemAction,
  [ACTIONS.SET_ITEM_QUERY]: setItemQuery
})
