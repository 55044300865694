import React from 'react';
import Page from './Page'

import Separator from '../elements/Separator';
import ReturnOrderForm from '../forms/ReturnOrderForm';
import PageHeaderOutline from '../layouts/PageHeaderOutline';

import './Page.css';
import './ReturnOrderPage.css';

class ReturnOrderPage extends Page {
  render() {
    return (
      <div className="ReturnOrderPage Page">
        <PageHeaderOutline title="Return & Exchange" top={this._backButton()}></PageHeaderOutline>
        <p>Request to return or replace a product.</p>
        <ReturnOrderForm/>
      </div>
    );
  }
}

export default ReturnOrderPage;
