import React from 'react';
import Element from './Element';
import './Input.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Input extends Element {

  constructor(props) {
    super(props);
    let value = this.props.value;
    this.state = {
      focused:false,
      filled: value && value.length > 0,
      value: value
    };
  }

  onKeyDown(evt) {
    if (evt.key === 'Enter') {
      if (this.props.onEnter) {
        this.props.onEnter(evt);
      }
    }
  }

  onFocus(evt) {
    this.setState({focused:true});
    if (this.props.onFocus) {
      this.props.onFocus(evt);
    }
  }

  onBlur(evt) {
    this.setState({focused:false});
    if (this.props.onBlur) {
      this.props.onBlur(evt);
    }
  }

  onChange(evt) {
    // Update State
    this.setState({
      value: evt.target.value,
      filled: evt.target.value.length > 0
    });

    // Check for onChange callback
    if (this.props.onChange) {
      this.props.onChange(evt.target.value);
    }
  }

  onClear(evt) {
    // Update State
    this.setState({
      value: "",
      filled: false
    });

    // Check for onChange callback
    if (this.props.onChange) {
      this.props.onChange("");
    }

    // Check for onClear callback
    if (this.props.onClear) {
      this.props.onClear(evt);
    }

    // Focust on the input
    // setTimeout(()=>{
    //   this.refs.input.focus();
    // },25);
  }

  render() {

    // Check for label
    let label = <div/>
    if (this.props.label) {
      label = <div className="Label">{this.props.label}</div>
    }

    // Get the value
    let value = this.state.focused ? undefined : (this.props.value === undefined ? '' : this.props.value);

    // Determine if the input is filled
    let filled = value ? value.length > 0 : this.state.filled;

    let className = this.props.className || "";
    return (
      <div className={`Input ${className}`} ref="root"
        data-focused={this.state.focused}
        data-filled={filled}
        data-lock={this.props.lock}
        data-error={this.props.error}
        data-clearable={this.props.clearable}>
        {label}
        <input className="Field" ref="input"
          name={this.props.name}
          onKeyDown={(evt)=>this.onKeyDown(evt)}
          onFocus={(evt)=>this.onFocus(evt)}
          onBlur={(evt)=>this.onBlur(evt)}
          onChange={(evt)=>this.onChange(evt)}
          type={this.props.type || "text"}
          autoComplete="off"
          value={value}
          />
        <div className="Control" onClick={(evt)=>this.onClear(evt)}><FontAwesomeIcon icon={["fal","times"]} /></div>
      </div>
    );
  }
}

export default Input;
