import React from 'react';
import Page from './Page'

import PageHeaderOutline from '../layouts/PageHeaderOutline';
import Separator from '../elements/Separator';

import './Page.css';
import './ECStartPage.css';

import FAQLayout from '../layouts/FAQLayout';
import { EC_FAQS } from '../../constants/support/ECFAQs'
import { dot } from '../../lib/obj'

class ECStartPage extends Page {
  render() {
    return (
      <div className="ECStartPage Page">
        <p>{EC_FAQS.description}</p>
        <Separator></Separator>
        <FAQLayout faq={EC_FAQS} question={dot(this.props,"query.question")}/>
      </div>
    );
  }
}

export default ECStartPage
