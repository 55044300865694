import React from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import Menu from './Menu';
import './BottomMenu.css';
import MenuItemLayout from '../layouts/MenuItemLayout';
import { LEFT_MENU } from '../../constants/menus/LeftMenu';
import { expandMenu } from '../../actions/MenuActions';
import { openPopup } from '../../actions/PopupActions';
import PlainButton from '../elements/PlainButton';
import RectButton from '../elements/RectButton';
import DropControl from '../controls/DropControl';
import { dot } from '../../lib/obj';
import { logoutSession } from '../../actions/SessionActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class BottomMenu extends Menu {

  onStore(evt) {
    window.location = "https://magbak.com";
  }

  onHome(evt,url) {
    this.props.history.push("/");
  }

  render() {
    return (
      <div className="BottomMenu" ref="root">
        <div className="Content">
          <div className="Social">
            <a href="https://www.facebook.com/realmagbak" target="_blank"><FontAwesomeIcon icon={["fab","facebook"]} /></a>
            <a href="https://www.instagram.com/magbakofficial" target="_blank"><FontAwesomeIcon icon={["fab","instagram"]} /></a>
            <a href="https://twitter.com/magbaksays" target="_blank"><FontAwesomeIcon icon={["fab","twitter"]} /></a>
          </div>
          <div className="Foot">
            <a href="https://magbak.com/policies/privacy-policy">Privacy&nbsp;Policy</a> |&nbsp;
            <a href="https://magbak.com/policies/terms-of-service">Terms&nbsp;Of&nbsp;Service</a>
            <br/>
            ©&nbsp;{(new Date()).getFullYear()}&nbsp;1Lss&nbsp;Inc. All&nbsp;Rights&nbsp;Reserved.
          </div>
        </div>
      </div>
    );
  }
}

const mapState = (state, props) => {
  let menuName = props.name || "top";
  return {
    menuName: menuName,
    menu: state.menus[menuName] || {expanded:true},
    session: state.session
  }
};

const mapDispatch = (dispatch) => {
  return {
    expandMenu: opts => dispatch(expandMenu(opts)),
    openPopup: opts => dispatch(openPopup(opts)),
    logoutSession: opts => dispatch(logoutSession(opts))
  }
};

export default connect(
  mapState,
  mapDispatch
)(withRouter(BottomMenu))
