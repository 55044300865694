import { _ } from "./underscore";
import moment from 'moment';

const ABBR_NUM_ORDER = [
  {num:1E15, den:"P"},
  {num:1E12, den:"T"},
  {num:1E9, den:"B"},
  {num:1E6, den:"M"},
  {num:1E3, den:"K"},
];


const ABBR_DATA_SIZE_ORDER = [
  {num:1E15, den:"PB"},
  {num:1E12, den:"TB"},
  {num:1E9, den:"GB"},
  {num:1E6, den:"MB"},
  {num:1E3, den:"KB"},
];

const DAY_MS = 86400000;
const WEEK_MS = DAY_MS * 7;
const MONTHS = [
  {name:"January", short:"Jan"},
  {name:"February", short:"Feb"},
  {name:"March", short:"Mar"},
  {name:"April", short:"Apr"},
  {name:"May", short:"May"},
  {name:"June", short:"June"},
  {name:"July", short:"Jul"},
  {name:"August", short:"Aug"},
  {name:"September", short:"Sep"},
  {name:"October", short:"Oct"},
  {name:"November", short:"Nov"},
  {name:"December", short:"Dec"}
];

const DAYS = [
  {name:"Sunday", short:"Sun"},
  {name:"Monday", short:"Mon"},
  {name:"Tuesday", short:"Tue"},
  {name:"Wednesday", short:"Wed"},
  {name:"Thursday", short:"Thu"},
  {name:"Friday", short:"Fri"},
  {name:"Saturday", short:"Sat"}
];

/**
 * Converts a percentage (0-100) to a degree rotation value
 */
export const financial = function(value, {currency='$', commas=true, compact=true}={}) {
  let fixed = (Number.parseFloat(value) || 0.0).toFixed(2);
  if (commas) {
    let numParts = fixed.split(".");
    numParts[0] = commafy(parseInt(numParts[0]) || 0);
    fixed = numParts.join(".");
  }
  if (compact) {
    fixed = fixed.replace(/\.0+$/,"");
  }
  return currency ? currency+fixed : fixed;
};

/**
 * Converts the number into a string and adds commas
 */
export const commafy = function(num) {
  return num.toLocaleString('en', {useGrouping:true})
}


/**
 * Abbreviates the number and appends denomiation
 */
export const abbr = function(num, {fix=null, prefix="", type="number"}={}) {
  let abs = Math.abs(num);
  let abbrOrder = ABBR_NUM_ORDER;
  switch (type) {
    case "data":
      abbrOrder = ABBR_DATA_SIZE_ORDER
      break;
  }
  if (abs >= 1000) {
    for (var i = 0; i < abbrOrder.length; i++) {
      let order = abbrOrder[i];
      if (abs >= order.num) {
        let sign = num > 0 ? "" : "-";
        let quot = (abs / order.num);
        if (fix===null) {
          fix = (quot > 100) ? 0 : (quot > 10) ? 1 : 2;
        }
        num = sign + prefix + quot.toFixed(fix).replace(/(0+|\.0+)$/, '') + order.den;
        break;
      }
    }
  }
  return num;
}


/**
 * Converts the number into a string and adds commas
 */
export const capitalize = function(str) {
  return str && str.length > 0 ? str[0].toUpperCase() + str.substring(1) : str;
}


/**
 * Datify
 * ref: https://momentjs.com/docs/#/displaying/format/
 */
export const datify = function(date) {
  return moment.utc(date).local().calendar(null, {
    sameDay: 'h:mm a zz',
    nextDay: '[Tomorrow]',
    nextWeek: '[Next] dddd',
    lastDay: '[Yesterday]',
    lastWeek: 'dddd',
    sameElse: 'MMM DD, YYYY'
  });
}


/**
 * Trims the pre and post whitespace from the string
**/
export const trim = function(str) {
  if (!_.isString(str)) { return str; }
  return str.replace(/^\s+|\s+$/g,'');
}


/**
 * Checks to see if the string is an email
**/
export const isValidEmail = function(str) {
  if (!str || !_.isString(str) ) { return false; }
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(str);
}

/**
 * Parses the query string to a hash map
**/
export const parseQuery = function(str) {
  let map = {};
  if (!_.isString(str)) { return map; }
  str = str.replace(/^\?/,'');
  let terms = str.split('&');
  terms.forEach((term)=>{
    let parts = term.split('=');
    map[parts[0]] = decodeURIComponent(parts[1]);
  });
  return map;
}

/**
 * Stringifies query string to a hash map
**/
export const stringifyQuery = function(map = {}) {
  let keys = Object.keys(map)
  let terms = [];
  keys.forEach((key)=>{
    let val = map[key];
    if (val !== null && val !== undefined) {
      terms.push(`${key}=${encodeURIComponent(map[key])}`);
    }
  });
  return terms.join('&');
}
