import React from 'react';
import logo from './logo.svg';
import './App.css';
import Main from './components/Main';
import smoothscroll from 'smoothscroll-polyfill';
import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faChevronDown,
  faCheck,
  faEye,
  faPrint,
  faPlusCircle,
  faTachometer,
  faIndustryAlt,
  faTruck,
  faGlobeAmericas,
  faObjectGroup,
  faTag,
  faLayerMinus,
  faLayerPlus,
  faHome,
  faSortAlt,
} from '@fortawesome/pro-solid-svg-icons'

import {
  faArrowToTop,
  faBan,
  faCopy,
  faConstruction,
  faCheckCircle,
  faEdit,
  faExchange,
  faFile,
  faFileInvoice,
  faFragile,
  faHandshake,
  faHourglass,
  faLink,
  faMoneyCheckAlt,
  faPaperclip,
  faQuestionCircle,
  faQuestionSquare,
  faSearch,
  faShoppingCart,
  faStoreAlt,
  faSync,
  faTasks,
  faTimes
} from '@fortawesome/pro-light-svg-icons'

import {
  faBox,
  faBoxes,
  faCaretLeft,
  faCaretRight,
  faChevronLeft,
  faChevronRight,
  faCheckSquare,
  faCircle,
  faCloudUploadAlt,
  faCube,
  faCubes,
  faDotCircle,
  faExclamationTriangle,
  faFilePdf,
  faFilePlus,
  faFilter,
  faPencil,
  faRedoAlt,
  faSquare,
  faThumbsUp,
  faTrashAlt,
} from '@fortawesome/pro-regular-svg-icons'


import {
  faFacebook,
  faInstagram,
  faShopify,
  faTwitter
} from '@fortawesome/free-brands-svg-icons'


library.add(
  faArrowToTop,
  faBan,
  faBox,
  faBoxes,
  faCaretLeft,
  faCaretRight,
  faCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faCloudUploadAlt,
  faConstruction,
  faCopy,
  faCube,
  faCubes,
  faDotCircle,
  faEdit,
  faExchange,
  faExclamationTriangle,
  faEye,
  faFacebook,
  faFilter,
  faFile,
  faFileInvoice,
  faFilePdf,
  faFilePlus,
  faFragile,
  faGlobeAmericas,
  faHandshake,
  faHome,
  faHourglass,
  faIndustryAlt,
  faInstagram,
  faLayerMinus,
  faLayerPlus,
  faLink,
  faMoneyCheckAlt,
  faPaperclip,
  faPrint,
  faPlusCircle,
  faTachometer,
  faTasks,
  faShoppingCart,
  faObjectGroup,
  faQuestionCircle,
  faQuestionSquare,
  faPencil,
  faRedoAlt,
  faSearch,
  faShopify,
  faSortAlt,
  faSquare,
  faStoreAlt,
  faSync,
  faTag,
  faTimes,
  faThumbsUp,
  faTrashAlt,
  faTruck,
  faTwitter
)

// kick off the polyfill!
smoothscroll.polyfill();

function App() {
  return (
    <Main/>
  );
}

export default App;
