import React from 'react';
import { withRouter } from 'react-router'
import Layout from './Layout';
import './PageHeaderOutline.css';

import Row from '../elements/Row';
import Readout from '../elements/Readout';
import RoundButton from '../elements/RoundButton';

class PageHeaderOutline extends Layout {


  render(){
    return (
      <div className="PageHeaderOutline">
        <div className="HeaderTop">
          {this.props.top}
        </div>
        <div className="HeaderMiddle">
          <div className="Title">{this.props.title}</div>
          <div className="Aside">{this.props.aside}</div>
        </div>
        <div className="HeaderBottom">
          {this.props.children}
        </div>
      </div>
    )
  }
}

const RoutedPageHeaderOutline= withRouter(PageHeaderOutline)
export default RoutedPageHeaderOutline;
