import React from 'react';
import ReactDOM from 'react-dom';
import Control from './Control';
import { dot } from '../../lib/obj';

import './RadioControl.css';

class RadioControl extends Control {

  constructor(props) {
    super(props);
    this.state = {
      index: props.index || 0
    };
  }

  componentDidMount() {
    let index = this.state.index;
    let child = this.props.children[index];
    if (child) {
      setTimeout(()=>{
        this.onChange(this.state.index, child.props);
      },25);
    }
  }

  onChange(index, props) {
    if (this.props.onChange) {
      this.props.onChange(index,props);
    }
    this.setState({index:index});
  }

  render() {
    let state = this.state;
    let className = this.props.className || "";
    return (
      <div className={`RadioControl ${className}`} ref="root">
        {this.props.children.map((child, i) => {
          return (<div className="Option" key={`option${i}`} onClick={(evt)=>{this.onChange(i,child.props)}} data-selected={i==state.index}>
            {child}
          </div>);
        })}
      </div>
    );
  }
}

export default RadioControl
