import React from 'react';

class Popup extends React.Component {


  constructor(props) {
    super(props);
    this.state = {};
  }

  onFrameClick(e) {
    e.stopPropagation();
  }

  onClose(evt) {
    if (this.props.closePopup) {
      this.props.closePopup();
    }
  }

  onCancel(evt, history) {
    if (this.props.onCancel) {
      this.props.onCancel(evt, history);
    }
  }

  onOk(evt, history) {
    if (this.props.onOk) {
      this.props.onOk(evt, history);
    }
  }

  render() {
    return (
      <div>Hello popup!</div>
    );
  }
}

export default Popup;
