import React from 'react';
import { connect } from 'react-redux'
import OrderForm from './OrderForm'
import Input from '../elements/Input';
import TextArea from '../elements/TextArea';
import RectButton from '../elements/RectButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { trim } from '../../lib/formats';
import { dot } from '../../lib/obj';

import './Form.css';
import './NewOrderForm.css';

class NewOrderForm extends OrderForm {

  ticketType() {
    return 'order-new';
  }

  render() {
    let state = this.state;

    // Check the ticket status
    if (state.ticketState == "requested") {
      return (
        <div className="Form">
          <p>Thank you for contacting the MagBak Support Team!</p>
          <p>We have received your inquiry and will be getting back to you very soon.</p>
          <p>Our support hours are M-F 9AM-5PM US Pacific Time.</p>
          <p>We appreciate you choosing MagBak and look forward to resolving any concerns.</p>
          <p>Cheers!<br/>The MagBak Team</p>
          <FontAwesomeIcon className="Icon" icon={["fal","check-circle"]} />
        </div>
      );
    }
    else {

      // Check if there is an error
      let error = state.errorField != null || state.ticketState == "error";

      // Check if sending ticket
      let sending = this.isSending();

      // Put refs on fields that are required
      return (
        <div ref="root" className="Form NewOrderForm">
          <Input ref="name" className="Name" label="Full Name *" value={this.state.name} error={state.errorField == 'name'} lock={sending} onChange={(value)=>{this.onEditField('name',value)}}/>
          <Input ref="email" className="Email" label="Email *" type="email" value={this.state.email}  error={state.errorField == 'email'} lock={sending} onChange={(value)=>{this.onEditField('email',value)}}/>
          <TextArea className="Comment" label="Comment" value={this.state.comment} lock={sending} onChange={(value)=>{this.onEditField('comment',value)}}/>
          <RectButton className="Submit" theme="darkblue" working={sending} onClick={(evt)=>{this.onSubmit(evt)}}>{sending ? "WORKING" : "SUBMIT"}</RectButton>
          <p className="Shout" data-error={error}>{state.shout || "* Fields are required."}</p>
        </div>
      );
    }

  }
}

export default NewOrderForm;
