import React from 'react';
import { connect } from 'react-redux'
import Page from './Page'

import PixBoxLayout from '../layouts/PixBoxLayout';
import Separator from '../elements/Separator';
import PixBoxMenu from '../menus/PixBoxMenu';
import PageHeaderOutline from '../layouts/PageHeaderOutline';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './Page.css';
import './Dashboard.css';

class Dashboard extends Page {

  onTestClick() {
    this.props.openPopup({ name:"Upload" });
  }

  render() {
    return (
      <div className="Dashboard Page">
        <PageHeaderOutline title="Hello!" top={this._backButton()}></PageHeaderOutline>
        <p>How can we help you?</p>
        <Separator></Separator>
        <PixBoxMenu>
          <PixBoxLayout key="faq" onClick={(evt, history)=>{this.goto(evt, history,"/faq")}}>
            <div className="Icon"><FontAwesomeIcon icon={["fal","question-circle"]} /></div>
            <div className="Title">FAQs</div>
            <p className="Description">Frequently asked questions and product guides.</p>
          </PixBoxLayout>
          <PixBoxLayout key="order" onClick={(evt, history)=>{this.goto(evt, history,"/order")}}>
            <div className="Icon"><FontAwesomeIcon icon={["fal","file-invoice"]} /></div>
            <div className="Title">Order Help</div>
            <p className="Description">Request assistance with an active or new order.</p>
          </PixBoxLayout>
          <PixBoxLayout key="rma" onClick={(evt, history)=>{this.goto(evt, history,"/rma")}}>
            <div className="Icon"><FontAwesomeIcon icon={["fal","exchange"]} /></div>
            <div className="Title">Returns & Exchanges</div>
            <p className="Description">Request to return, replace, or exchange a product.</p>
          </PixBoxLayout>
        </PixBoxMenu>
        <Separator></Separator>
        <PixBoxMenu>
          <PixBoxLayout key="sales" onClick={(evt, history)=>{this.goto(evt, history,"/sales")}}>
            <div className="Icon"><FontAwesomeIcon icon={["fal","handshake"]} /></div>
            <div className="Title">Become A Distributor</div>
            <p className="Description">B2B, distribution, resale partners, and bulk orders.</p>
          </PixBoxLayout>
        </PixBoxMenu>
        <Separator></Separator>
        <p>Our support hours are M-F 9AM-5PM US Pacific Time.</p>
        <p>The Office is closed on certain U.S. holidays.</p>
      </div>
    );
  }
}

export default Dashboard
