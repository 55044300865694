import React from 'react';
import Page from './Page'

import ECExperienceSurvey from '../surveys/ECExperienceSurvey';
import PageHeaderOutline from '../layouts/PageHeaderOutline';

import './Page.css';
import './ECExperienceSurveyPage.css';

class ECExperienceSurveyPage extends Page {
  render() {
    return (
      <div className="ECExperienceSurveyPage Page">
        <PageHeaderOutline title="Tell us about your Elite Case experience." top={this._backButton()}></PageHeaderOutline>
        <ECExperienceSurvey/>
      </div>
    );
  }
}

export default ECExperienceSurveyPage
