import React from 'react';
import ReactDOM from 'react-dom';
import { withRouter } from 'react-router'
import Layout from './Layout';
import './SearchLayout.css';

import { dot } from '../../lib/obj';
import { _ } from "../../lib/underscore";
import { financial, trim } from '../../lib/formats';
import { searchMatch, extractHTMLText } from '../../lib/search';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { PRODUCT_FAQS } from '../../constants/support/FAQs';

class SearchLayout extends Layout {

  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      term: ""
    };
  }

  expand() {
    if (!this.state.expanded) {
      this.setState({expanded:true},()=>{
        let elem = ReactDOM.findDOMNode(this.refs.input);
        elem.focus();
      });
    }
  }

  collapse() {
    if (this.state.expanded) {
      this.setState({expanded:false});
    }
  }

  clear() {
    if (this.state.term) {
      this.setState({term:""});
    } else {
      this.collapse();
    }
  }

  onTermChange(evt) {
    this.setState({term:evt.target.value});
  }

  gotoQuestion(evt, id) {
    if (id) {
      let elem = document.querySelector(`#${id} > .Answer `);
      if (elem) {
        const y = elem.getBoundingClientRect().top + window.scrollY  - 125;
        window.scroll({
          top: y,
          behavior: 'smooth'
        });
      }
      else {
        this.props.history.push(`/faq?question=${id}`)
      }
    }
    evt.preventDefault();
    this.collapse();
  }

  render() {
    let faq = this.props.faq || PRODUCT_FAQS;
    let categories = faq.categories || [];
    let term = trim(this.state.term);

    if (term && term.length > 1) {
      categories = categories.map((category)=>{
        let questions = _.compact((category.questions || []).map((question)=>{
          let q = {
             title: searchMatch(term, question.title,{decorate:(f)=>`<b>${f}</b>`}),
             answer: searchMatch(term,extractHTMLText(question.answer),{decorate:(f)=>`<b>${f}</b>`})
          }
          if (q.title || q.answer) {
            return {
              title: q.title || question.title,
              // answer: q.answer,
              url: question.url
            }
          }
          return null;
        }));
        return { questions: questions };
      });
      categories = categories.filter((category)=>category.questions.length != 0)
    } else {
      categories = [];
    }

    // Determine search control
    let ctrl = <div key="times" className="Control" onClick={()=>{this.clear()}}><FontAwesomeIcon icon={["fal","times"]} /></div>;
    if (!term) {
      ctrl = <div key="chev" className="Control" onClick={()=>{this.clear()}}><FontAwesomeIcon icon={["far","chevron-right"]} /></div>;
    }

    let resultCtr=0;
    return (
      <div className="SearchLayout" ref="root" onClick={(evt)=>{this.expand()}} data-expanded={this.state.expanded}>
        <div className="Content">
          <form className="Bar" action="/search">
            <input ref="input" name="term" type="search" className="Term" placeholder="Search" value={this.state.term} onChange={(evt)=>{this.onTermChange(evt)}}/>
            <div className="Icon">Search <FontAwesomeIcon icon={["fal","search"]} /></div>
            {ctrl}
          </form>
          <div className="Results" data-active={categories.length > 0 && this.state.expanded}>
            {categories.map((category, index)=>{
              return <div key={`category${index}`} className="Category" data-collapsed={category.collapsed}>
                {(category.questions || []).map((question, qi)=>{
                  let qid = question.url ? question.url.replace(/^#/,'') : question.url;
                  return <div key={`question${qi}`} className="Question" onClick={(evt)=>{this.gotoQuestion(evt, qid)}}>
                    <span className="Index">{++resultCtr}.</span>
                    <span className="Title" dangerouslySetInnerHTML={{__html:question.title}}></span>
                    {/* <span className="Answer" dangerouslySetInnerHTML={{__html:question.answer}}></span>*/}
                  </div>
                })}
              </div>
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(SearchLayout);
