import React from 'react';
import { Link } from 'react-router-dom';
import Popup from './Popup';
import RectButton from '../elements/RectButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import './Popup.css';
import './OrderStatusPopup.css';

import { dot } from '../../lib/obj';
import { closePopup } from '../../actions/PopupActions';
import { connect } from 'react-redux'

class OrderStatusPopup extends Popup {

  onSignIn(evt, history) {
    window.open('https://magbak.com/account/login','_newtab');
    //this.onCancel(evt, history);
  }

  missingOrderMessage() {
    /*
    return <div>
      <h2 className="Title">We did not find your order</h2>
      <p>Please make sure your email and order number were entered correctly.</p>
      <p>Also, check your email inbox and spam folder for your order confirmation.</p>
      <p>Feel free to submit a <Link to="/order/status-request" onClick={(e)=>this.onClose(e)}>support request</Link> if you have other questions or comments. You can also sign into your account to view more details.</p>
    </div>;
    */
    return <div>
      <h2 className="Title">We did not find your order</h2>
      <p>Please make sure your email and order number were entered correctly.</p>
      <p>Also, check your email inbox and spam folder for your order confirmation.</p>
      <p>Feel free to submit a <Link to="/order/status-request" onClick={(e)=>this.onClose(e)}>support request</Link> if you have other questions or comments. You can also sign into your account to view more details.</p>
    </div>;
  }

  // See https://help.shopify.com/en/manual/orders/order-status#payment-status for more info
  cancelledMessage() {
    let data = this.props.order.data;
    let cancelledAt = new Date(data.cancelledAt);
    let odc = cancelledAt.toString().split(" ");

    let intro = <div>
      <h2 className="Title">We found your order!</h2>
      <p>Your order was cancelled on {odc[0]} {odc[1]} {odc[2]}, {odc[3]} (US Pacific Time).</p>
    </div>;
    let outro = <p>Please check your email inbox and spam folder for more details from us.</p>;
    let ps = <p>Feel free to submit a <Link to="/order/status-request" onClick={(e)=>this.onClose(e)}>support request</Link> if you have other questions or comments. You can also sign into your account to view more details.</p>;

    let message;
    switch (data.financialStatus) {
      case "refunded":
        message = <div>
          {intro}
          <p>The full amount that was paid for the order has been refunded.</p>
          <p>If the refund is being issued to a credit card, depending on your credit card company, it may take an additional 2-10 Business Days after your credit is applied for it to post to your account.</p>
          {outro}
          {ps}
        </div>;
        break;
      case "partially_refunded":
        message = <div>
          {intro}
          <p>A partial amount that was paid for the order has been refunded.</p>
          <p>If the refund is being issued to a credit card, depending on your credit card company, it may take an additional 2-10 Business Days after your credit is applied for it to post to your account.</p>
          {outro}
          {ps}
        </div>;
        break;
      case "voided":
        message = <div>
          <p>Any authorized payment amount has been voided.</p>
          {outro}
          {ps}
        </div>;
        break;
      default:
        message = <div>
          <p>We don't have information to display at this moment.</p>
          {outro}
          {ps}
        </div>;
    }
    return message;
  }

  fulfillmentMessage() {
    let data = this.props.order.data;
    let processedAt = new Date(data.processedAt);
    let odp = processedAt.toString().split(" ");

    let intro = <div>
      <h2 className="Title">We found your order!</h2>
      <p>Your order was processed on {odp[0]} {odp[1]} {odp[2]}, {odp[3]} (US Pacific Time).</p>
    </div>;
    let outro = <p>Please check your email inbox and spam folder for more details from us.</p>;
    let ps = <p>Feel free to submit a <Link to="/order/status-request" onClick={(e)=>this.onClose(e)}>support request</Link> if you have other questions or comments.</p>;

    let sscs = {};
    data.fulfillments.forEach((f) => {
      f.items.forEach((i) => {
        sscs[i.sku] = (sscs[i.sku] || 0) + i.quantity;
      });
    });

    let tscs = {};
    let ibss = {};
    data.items.forEach((i) => {
      tscs[i.sku] = (tscs[i.sku] || 0) + i.quantity;
      if (!ibss[i.sku]) { ibss[i.sku] = i; }
    });

    let pscs = []
    Object.keys(tscs).forEach((sku) => {
      let pcount = (tscs[sku] || 0) - (sscs[sku] || 0);
      if (pcount > 0) {
        pscs.push({
          name: ibss[sku].name,
          quantity: pcount
        });
      }
    });

    let message;
    switch (data.fulfillmentStatus) {
      case "partial":
        let pending;
        if (pscs.length > 0) {
          pending = <li>
            <p>Items waiting to be shipped:</p>
            <ul>
              {pscs.map((i)=>{
                return <li>{i.quantity} &times; {i.name}</li>
              })}
            </ul>
          </li>
        }
        message = <div className="Message">
          {intro}
          <p>Some items have been fulfilled.</p>
          <p>Below you will find the shipping and tracking information: </p>
          <ul>
            {data.fulfillments.map((f)=><li>
              <p>Sent via {f.trackingCompany} {f.trackingNumbers.map((t,i)=><a href={f.trackingURLs[i]} target="_blank">{t}</a>)}</p>
              <ul>
                {f.items.map((i)=><li>{i.quantity} &times; {i.name}</li>)}
              </ul>
            </li>)}
            {pending}
          </ul>
          {outro}
          {ps}
        </div>;
        break;
      case "fulfilled":
        message = <div className="Message">
          {intro}
          <p>All items have been fulfilled.</p>
          <p>Below you will find the shipping and tracking information: </p>
          <ul>
            {data.fulfillments.map((f)=><li>
              <p>Sent via {f.trackingCompany} {f.trackingNumbers.map((t,i)=><a href={f.trackingURLs[i]} target="_blank">{t}</a>)}</p>
              <ul>
                {f.items.map((i)=><li>{i.quantity} &times; {i.name}</li>)}
              </ul>
            </li>)}
          </ul>
          {outro}
          {ps}
        </div>;
        break;
      case "restocked":
        message = <div className="Message">
          {intro}
          <p>All items have been restocked because of an order refund or cancellation.</p>
          {outro}
          {ps}
        </div>;
        break;
      default:
        message = <div className="Message">
          {intro}
          <p>It is pending shipment.</p>
          <p>Orders typically ship in 1-3 business days. Please check your email inbox and spam folder for more details from us.</p>
          {outro}
          {ps}
        </div>;
    }
    return message;
  }

  render() {
    let order = this.props.order;
    let data = order.data;

    let message;
    if (order.status == "missing") {
      message = this.missingOrderMessage();
    }
    else if (data.cancelledAt) {
      message = this.cancelledMessage();
    } else {
      message = this.fulfillmentMessage();
    }

    return (
      <div className="Popup OrderStatusPopup" ref="root" onClick={(e)=>this.onClose(e)}>
        <div className="PopupFrame" onClick={(e)=>this.onFrameClick(e)}>
          {message}
          <div class="Controls">
            <RectButton className="Cancel" theme="bare" onClick={(evt,history)=>{this.onSignIn(evt,history)}}>Sign In</RectButton>
            <RectButton className="OK" theme="darkblue" onClick={(evt,history)=>{this.onOk(evt, history)}}>OK</RectButton>
          </div>
        </div>
        <div className="Close"><FontAwesomeIcon icon={["fal","times"]} /></div>
      </div>
    );
  }
}


const mapState = (state, props) => {
  let order = dot(state,["support","status","orders",props.order]);
  return {
    order: order || {}
  }
};

const mapDispatch = (dispatch) => {
  return {
    closePopup: opts => dispatch(closePopup(opts))
  }
};

export default connect(
  mapState,
  mapDispatch
)(OrderStatusPopup)
