import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import OrderForm from './OrderForm'
import Input from '../elements/Input';
import TextArea from '../elements/TextArea';
import RectButton from '../elements/RectButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { editForm, startForm, clearForm } from '../../actions/FormActions.js';
import { getOrderStatus } from '../../actions/SupportActions.js';
import { openPopup, closePopup } from '../../actions/PopupActions.js';
import { trim, isValidEmail } from '../../lib/formats';
import { dot } from '../../lib/obj';
import { _ } from '../../lib/underscore';

import './Form.css';
import './OrderStatusForm.css';

class OrderStatusForm extends OrderForm {

  componentWillMount() {
    this.props.startForm({name:"status"});
  }

  onEditField(field, value) {
    this.props.editForm({name:"status", field:field, value: value});
  }

  onSubmit(evt) {
    // Check fields and see if we need to abort submit
    if (!this.validateFields()) { return; }

    let data = this.props.form.data;
    if (data.order && data.email) {
      let num = data.order.replace("#","");
      this.props.getOrderStatus({order:num, email:data.email, onSuccess:()=>{
        if (this.props.onStatusResponse) {this.props.onStatusResponse();}
      }});
    }
  }

  emailPasses() {
    if (this.refs.email) {
      let data = this.props.form.data || {};
      if (!trim(data.email)) {
        this.scrollToRef(this.refs.email);
        this.setState({
          errorField: "email",
          shout:"Please enter an email."
        });
        return false;
      }
      if (!isValidEmail(data.email)) {
        this.scrollToRef(this.refs.email);
        this.setState({
          errorField: "email",
          shout:"Please enter a valid email."
        });
        return false;
      }
    }
    return true;
  }

  orderPasses() {
    let data = this.props.form.data || {};
    if (this.refs.order && !trim(data.order)) {
      this.scrollToRef(this.refs.order);
      this.setState({
        errorField: "order",
        shout:"Please enter an order #."
      });
      return false;
    }
    return true;
  }

  // Check if ticket is being sent
  isSending() {
    let state = this.state;
    return state.statusState && state.statusState != "error" && state.statusState != "bounced";
  }

  render() {
    let state = this.state;
    let form = this.props.form;
    let order = this.props.order;

    // Check if there is an error
    let error = state.errorField != null || state.statusState == "error";

    // Check if sending ticket
    let sending = order.status == "loading";

    let note;
    if (this.props.note) {
      note = <p className="OrderNote">{ this.props.note }</p>;
    }
    else {
      note = <p className="OrderNote">Don't know your order number? <Link to="/order/status-request">Request order status here</Link></p>;
    }


    let reshout = this.props.shoutForError ? this.props.shoutForError(state.errorField) : null;
    let shout = reshout || state.shout;

    // Put refs on fields that are required
    return (
      <div className="Form OrderStatusForm">
        <Input ref="email" className="Email" label="Email *" type="email" value={dot(form,'data.email')}  error={state.errorField == 'email'} lock={sending} onChange={(value)=>{this.onEditField('email',value)}} onBlur={()=>{this.onBlurField('email')}}/>
        <Input ref="order" className="Order" label="Order # *" value={dot(form,'data.order')} lock={sending} error={state.errorField == 'order'} onChange={(value)=>{this.onEditField('order',value)}} onBlur={()=>{this.onBlurField('order')}}/>
        { note }
        <RectButton className="Submit" theme="darkblue" working={sending} onClick={(evt)=>{this.onSubmit(evt)}}>{sending ? "WORKING" : "SUBMIT"}</RectButton>
        <p className="Shout" data-error={error}>{shout || "* Fields are required."}</p>
      </div>
    );

  }
}


const mapState = (state, props) => {
  let orderNumber = dot(state,"forms.status.data.order");
  return {
    form: dot(state,"forms.status") || {},
    order: dot(state,["support","status","orders",orderNumber]) || {}
  }
};

const mapDispatch = (dispatch) => {
  return {
    getOrderStatus: opts => dispatch(getOrderStatus(opts)),
    openPopup: opts => dispatch(openPopup(opts)),
    closePopup: opts => dispatch(closePopup(opts)),
    startForm: opts => dispatch(startForm(opts)),
    editForm: opts => dispatch(editForm(opts)),
    clearForm: opts => dispatch(clearForm(opts)),
  }
};

export default connect(
  mapState,
  mapDispatch
)(OrderStatusForm)
