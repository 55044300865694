import React from 'react';
import Page from './Page'

import ECUnboxingSurvey from '../surveys/ECUnboxingSurvey';
import PageHeaderOutline from '../layouts/PageHeaderOutline';

import './Page.css';
import './ECUnboxingSurveyPage.css';

class ECUnboxingSurveyPage extends Page {
  render() {
    return (
      <div className="ECUnboxingSurveyPage Page">
        <PageHeaderOutline title="Tell us about your Elite Case unboxing experience." top={this._backButton()}></PageHeaderOutline>
        <ECUnboxingSurvey/>
      </div>
    );
  }
}

export default ECUnboxingSurveyPage
