import React from 'react';
import ReactDOM from 'react-dom';
import Control from './Control';
import { dot } from '../../lib/obj';

import './MultiControl.css';

class MultiControl extends Control {

  constructor(props) {
    super(props);
    const selections = new Set();
    props.children.forEach((child, i) => {
      if (child.props['data-selected']) {
        selections.add(i);
      }
    });
    this.state = {
      selections: selections
    };
  }

  onOptionClick(index) {
    let sels = this.state.selections;
    if (!sels.has(index)) {
      sels.add(index);
    } else {
      sels.delete(index);
    }
    this.onChange(sels);
  }

  onChange(sels) {
    if (this.props.onChange) {
      let children = this.props.children;
      let parr = children.filter((c,i)=>sels.has(i)).map((c)=>c.props);
      this.props.onChange(sels, parr);
    }
    this.setState({selections:sels});
  }

  render() {
    let state = this.state;
    let className = this.props.className || "";
    return (
      <div className={`MultiControl ${className}`} ref="root">
        {this.props.children.map((child, i) => {
          return (<div className="Option" key={`option${i}`} onClick={(evt)=>{this.onOptionClick(i)}} data-selected={state.selections.has(i)}>
            {child}
          </div>);
        })}
      </div>
    );
  }
}

export default MultiControl
