import React from 'react';
import { connect } from 'react-redux'
import OrderForm from './OrderForm'
import Input from '../elements/Input';
import TextArea from '../elements/TextArea';
import RectButton from '../elements/RectButton';
import DropControl from '../controls/DropControl';
import FileControl from '../controls/FileControl';
import SugControl from '../controls/SugControl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { trim } from '../../lib/formats';
import { dot } from '../../lib/obj';

import './Form.css';
import './ExchangeOrderForm.css';

import { PRODUCT_INDEX, VARIANT_MAP } from '../../constants/support/Products';
import { SHIP_METHODS } from '../../constants/misc/ShipMethods';

const RETURN_REASONS = [
  {
    name: "Ordered wrong product",
    value: "wrong"
  },
  {
    name: "Received wrong product",
    value: "mistake"
  },
  {
    name: "Unhappy with product",
    value: "unhappy"
  },
  {
    name: "Other",
    value: "other"
  }
];


class ExchangeOrderForm extends OrderForm {

  ticketType() {
    return 'order-exchange';
  }

  onFileItemsChanged(items) {
    let files = [];
    if (items) {
      items.forEach((item)=>{
        if (item.status == "loaded") {
          files.push({loc:item.loc});
        }
      });
    }
    this.setState({
      files:files,
      errorField: null,
      ticketState: null,
      shout: null
    });
  }

  onEditReasonField(option) {
    this.onEditField("reason",option.name);
  }

  onEditProduct(field, val) {
    let state = this.state;
    if (state.method) {
      this.onEditField("method","");
    }
    this.onEditField(field,val);
  }

  render() {
    let state = this.state;

    // Check the ticket status
    if (state.ticketState == "requested") {
      return (
        <div className="Form">
          <p>Thank you for contacting the MagBak Support Team!</p>
          <p>We have received your inquiry and will be getting back to you very soon.</p>
          <p>Our support hours are M-F 9AM-5PM US Pacific Time.</p>
          <p>We appreciate you choosing MagBak and look forward to resolving any concerns.</p>
          <p>Cheers!<br/>The MagBak Team</p>
          <FontAwesomeIcon className="Icon" icon={["fal","check-circle"]} />
        </div>
      );
    }
    else {

      // Check if there is an error
      let error = state.errorField != null || state.ticketState == "error";

      // Check if sending ticket
      let sending = this.isSending();

      let productInput;
      let requestInput;
      let filesControl = <FileControl className="Files" error={state.errorField == 'files'} onItemsChanged={(items)=>{this.onFileItemsChanged(items)}}/>;
      if (state.reason ==  RETURN_REASONS[0].name ) {
        productInput = <SugControl ref="productOrdered" className="Product" key="orderedProd" label="Product Ordered *" error={state.errorField == 'productOrdered'} lock={sending} onSuggest={(options, value)=>this.onSuggestProduct(options,value)} onChange={(value)=>{this.onEditField('productOrdered',value)}}/>
        requestInput  = <SugControl ref="productRequest" className="ProductRequest" label="Product Request *"  error={state.errorField == 'productRequest'} lock={sending} onSuggest={(options, value)=>this.onSuggestProduct(options,value)} onChange={(value)=>{this.onEditProduct('productRequest',value)}}/>
      } else if ( state.reason ==  RETURN_REASONS[1].name ) {
        productInput = <SugControl ref="productReceived" className="Product" key="receivedProd" label="Product Received *" error={state.errorField == 'productReceived'} lock={sending} onSuggest={(options, value)=>this.onSuggestProduct(options,value)} onChange={(value)=>{this.onEditField('productReceived',value)}}/>
        requestInput  = <SugControl ref="productRequest"  className="ProductRequest"  label="Product Request *"  error={state.errorField == 'productRequest'} lock={sending} onSuggest={(options, value)=>this.onSuggestProduct(options,value)} onChange={(value)=>{this.onEditProduct('productRequest',value)}}/>
        // Required files control with ref
        filesControl = <FileControl ref="files" className="Files" error={state.errorField == 'files'} onItemsChanged={(items)=>{this.onFileItemsChanged(items)}}/>;
      }

      // Get the ship methods based on the selected product
      let shipMethods = dot(VARIANT_MAP,[state.productRequest,"shipMethods"]) || SHIP_METHODS;

      // Put refs on fields that are required
      return (
        <div ref="root" className="Form ExchangeOrderForm" data-products={productInput != null}>
          <p className="Intro">Exchanges can only be requested within 30 days of product delivery.</p>
          <Input ref="name" className="Name" label="Full Name *" value={this.state.name} error={state.errorField == 'name'} lock={sending} onChange={(value)=>{this.onEditField('name',value)}}/>
          <Input ref="email" className="Email" label="Email *" type="email" value={this.state.email}  error={state.errorField == 'email'} lock={sending} onChange={(value)=>{this.onEditField('email',value)}}/>
          <Input ref="order" className="Order" label="Order # *" value={this.state.order} lock={sending} error={state.errorField == 'order'} onChange={(value)=>{this.onEditField('order',value)}}/>
          <p className="Desc">Please tell us which item you want to exchange and what you want to exchange it for. Attach any files to help us better understand your request.</p>
          <DropControl ref="reason" className="Reason" label="Reason *" options={RETURN_REASONS} value={this.state.reason} lock={sending} error={state.errorField == 'reason'} onOptionClick={(option)=>{this.onEditReasonField(option)}}/>
          {productInput}
          {requestInput}
          <TextArea ref="comment" className="Comment" label="Comment *" value={this.state.comment} lock={sending} error={state.errorField == 'comment'} onChange={(value)=>{this.onEditField('comment',value)}}/>

          <p className="Attach">Add a picture or video. (25MB limit)</p>
          {filesControl}
        
          <p className="Ship">How would you like us to ship your exchange?</p>
          <DropControl ref="method" className="Method" label="Shipping Method *" options={shipMethods} value={this.state.method} lock={sending} error={state.errorField == 'method'} onOptionClick={(option)=>{this.onEditField('method',option.name)}}/>
          <RectButton className="Submit" theme="darkblue" working={sending} onClick={(evt)=>{this.onSubmit(evt)}}>{sending ? "WORKING" : "SUBMIT"}</RectButton>
          <p className="Shout" data-error={error}>{state.shout || "* Fields are required."}</p>
        </div>
      );
    }

  }
}

export default ExchangeOrderForm;
