import React from 'react';
import Page from './Page'

import Separator from '../elements/Separator';
import PageHeaderOutline from '../layouts/PageHeaderOutline';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './Page.css';
import './ConstructionPage.css';

class ConstructionPage extends Page {
  render() {
    return (
      <div className="ConstructionPage Page">
        <PageHeaderOutline title="Under Construction" top={this._backButton()}></PageHeaderOutline>
        <p>This page is comming soon!</p>
        <FontAwesomeIcon style={{fontSize:"5em",color:"orange"}} icon={["fal","construction"]} />
      </div>
    );
  }
}

export default ConstructionPage;
