import React from 'react';
import { withRouter } from 'react-router'
import Element from './Element';
import './RectButton.css';

class RectButton extends Element {

  onClick(evt) {
    console.log("CLicked button");
    if (this.props.onClick) {
      this.props.onClick(evt, this.props.history);
    }
  }

  render() {
    let className = this.props.className || "";
    return (
      <button type="button" className={`RectButton ${className}`} ref="root"
        data-theme={this.props.theme}
        data-layout={this.props.layout}
        data-working={this.props.working}
        data-lock={this.props.lock}
        onClick={(evt)=>{this.onClick(evt)}}>
        {this.props.children}
      </button>
    );
  }
}

const RoutedRectButton = withRouter(RectButton)
export default RoutedRectButton;
