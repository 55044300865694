import React from 'react';

export const EC_FAQS = {
  title: "FAQs",
  description: "Welcome to the MagBak Elite Case Experience.",
  categories: [
    {
      title: "Case Guides and FAQs",
      collapsed: true,
      url: "#case",
      questions:[
        {
          title: "How do I install the screen protector?",
          answer:`
            <p>Watch this video to see the quick installation instructions.</p>
            <iframe class="landscape" src="https://player.vimeo.com/video/989814320" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
            <p>You can find the written PDF instructions <a href="/resources/pdf/15NG-Bare-Instructions-v6.pdf" target="_blank">here</a>.</p>
          `,
          url: "#cg-install"
        },
        {
          title: "Will the case magnets affect the phone?",
          answer:`
            <p>No. The Magnets have been strategically placed so that functions such as WiFi, LTE, GPS, and wireless charging work without any issues.</p>
          `,
          url: "#case-function"
        },
        {
          title: "Will the case magnets erase my credit cards?",
          answer:`
            <p>No. Common credit cards are safe and are NOT affected.</p>
          `,
          url: "#case-cards"
        },
        {
          title: "Does the case support wireless charging?",
          answer:`
            <p>Yes, the case supports wireless charging and any MagSafe accessories.</p>
          `,
          url: "#case-charging"
        },
        {
          title: "Do all MagBak Elite cases work with the MagBak Wallet?",
          answer:`
            <p>Yes. The latest MagBak Elite cases work with the MagBak Wallet:</p>
          `,
          url: "#case-wallet"
        },
        {
          title: "Does the case work with Apple's MagSafe wallet?",
          answer:`
            <p>Yes! Magnetic attachment is supported.</p>
            <p>If you are looking for a the best case + wallet solution check out <a target="_blank" href="https://magbak.com/products/magbak-wallet">MagBak wallet</a>.</p>
          `,
          url: "#case-magsafe-wallet"
        },
        {
          title: "Does the case work with Apple's MagSafe charger?",
          answer:`
            <p>Yes! Charging using Apple's MagSafe charger works with MagBak case.</p>
          `,
          url: "#case-magsafe-charger"
        },
        {
          title: "Which MagStick should I use with my MagBak case?",
          answer:`
            <p>The flat MagStick can be used with any case.</p>
            <img src="/resources/img/magstick-profile.jpg"/>
          `,
          url: "#case-magstick"
        },
        {
          title: "What material is the case made out of?",
          answer:`
            <p>The case is made out of a Policarbonate (PC) frame that is overmolded with a thermoplastic rubber (TPU). A soft-touch finish is added to provide grip and a smooth feel.</p>
          `,
          url: "#case-material"
        },
        {
          title: "How do I install the Color Buttons on my MagBak Case?",
          answer:`
            <p>Each 4-Color button set comes with 4 small (S) buttons, 8 medium (M) buttons and 4 large (L) buttons. </p>
            <img class="wide" src="/resources/img/button-set-sizes.png"/>
            <p>On the <b>iPhone 15 Pro and 15 Pro Max</b> case, use the small (S) button for the Action button, the medium (M) buttons for Volume up/down, and the large (L) button for Power.</p>
          `,
          url: "#case-color-buttons"
        },
        /*
        {
          title: "What are the dimensions of the MagBak case?",
          answer:`
          <p>MagBak Case for iPhone 15 series:</p>
          <p>
            <ul>
              <li><a target="_blank" href="/resources/pdf/MBC-15-6.7-Pro-Max-dimensions.pdf" title="iPhone 15 Pro Max Case Dimensions">iPhone 15 Pro Max Case Dimensions</a></li>
              <li><a target="_blank" href="/resources/pdf/MBC-15-6.1-Pro-dimensions.pdf" title="iPhone 15 Pro Case Dimensions">iPhone 15 Pro Case Dimensions</a></li>
              <li><a target="_blank" href="/resources/pdf/MBC-15-6.7-Plus-dimensions.pdf" title="iPhone 15 Plus Case Dimensions">iPhone 15 Plus Case Dimensions</a></li>
              <li><a target="_blank" href="/resources/pdf/MBC-15-6.1-dimensions.pdf" title="iPhone 15 Case Dimensions">iPhone 15 Case Dimensions</a></li>
            </ul>
          </p>
            <p>MagBak Case for iPhone 14 series:</p>
            <p>
              <ul>
                <li><a target="_blank" href="/resources/pdf/MBC-14-6.7-Max-Pro-dimensions.pdf" title="iPhone 14 Pro Max Color Case Dimensions">iPhone 14 Pro Max Color Case Dimensions</a></li>
                <li><a target="_blank" href="/resources/pdf/MBC-14-6.7-Max-Pro-Clear-dimensions.pdf" title="iPhone 14 Pro Max Clear Case Dimensions">iPhone 14 Pro Max Clear Case Dimensions</a></li>
                <li><a target="_blank" href="/resources/pdf/MBC-14-6.1-Pro-dimensions.pdf" title="iPhone 14 Pro Color Case Dimensions">iPhone 14 Pro Color Case Dimensions</a></li>
                <li><a target="_blank" href="/resources/pdf/MBC-14-6.1-Pro-Clear-dimensions.pdf" title="iPhone 14 Pro Clear Case Dimensions">iPhone 14 Pro Clear Case Dimensions</a></li>
                <li><a target="_blank" href="/resources/pdf/MBC-14-6.7-Max-dimensions.pdf" title="iPhone 14 Plus Color Case Dimensions">iPhone 14 Plus Color Case Dimensions</a></li>
                <li><a target="_blank" href="/resources/pdf/MBC-14-6.7-Max-Clear-dimensions.pdf" title="iPhone 14 Plus Clear Case Dimensions">iPhone 14 Plus Clear Case Dimensions</a></li>
                <li><a target="_blank" href="/resources/pdf/MBC-14-6.1-dimensions.pdf" title="iPhone 14 Color Case Dimensions">iPhone 14 Color Case Dimensions</a></li>
                <li><a target="_blank" href="/resources/pdf/MBC-14-6.1-Clear-dimensions.pdf" title="iPhone 14 Clear Case Dimensions">iPhone 14 Clear Case Dimensions</a></li>
              </ul>
            </p>
            <p>MagBak Case for iPhone 13 series:</p>
            <p>
              <ul>
                <li><a target="_blank" href="/resources/pdf/MBC-13-6.7-Pro-Max-dimensions.pdf" title="iPhone 13 Pro Max Color Case Dimensions">iPhone 13 Pro Max Color Case Dimensions</a></li>
                <li><a target="_blank" href="/resources/pdf/MBC-13-6.7-Pro-Max-Clear-dimensions.pdf" title="iPhone 13 Pro Max Clear Case Dimensions">iPhone 13 Pro Max Clear Case Dimensions</a></li>
                <li><a target="_blank" href="/resources/pdf/MBC-13-6.1-Pro-dimensions.pdf" title="iPhone 13 Pro Color Case Dimensions">iPhone 13 Pro Color Case Dimensions</a></li>
                <li><a target="_blank" href="/resources/pdf/MBC-13-6.1-Pro-Clear-dimensions.pdf" title="iPhone 13 Pro Clear Case Dimensions">iPhone 13 Pro Clear Case Dimensions</a></li>
                <li><a target="_blank" href="/resources/pdf/MBC-13-6.1-dimensions.pdf" title="iPhone 13 Color Case Dimensions">iPhone 13 Color Case Dimensions</a></li>
                <li><a target="_blank" href="/resources/pdf/MBC-13-6.1-Clear-dimensions.pdf" title="iPhone 13 Clear Case Dimensions">iPhone 13 Clear Case Dimensions</a></li>
                <li><a target="_blank" href="/resources/pdf/MBC-13-5.4-mini-dimensions.pdf" title="iPhone 13 mini Color Case Dimensions">iPhone 13 mini Color Case Dimensions</a></li>
              </ul>
            </p>
            <p>MagBak Case for iPhone 12 series:</p>
            <p>
              <ul>
                <li><a target="_blank" href="/resources/pdf/MBC-12-6.7-Max-dimensions.pdf" title="iPhone 12 Pro Max Color Case Dimensions">iPhone 12 Pro Max Color Case Dimensions</a></li>
                <li><a target="_blank" href="/resources/pdf/MBC-12-6.1-dimensions.pdf" title="iPhone 12 Pro Color Case Dimensions">iPhone 12 Pro Color Case Dimensions</a></li>
                <li><a target="_blank" href="/resources/pdf/MBC-12-5.4-mini-dimensions.pdf" title="iPhone 12 mini Color Case Dimensions">iPhone 12 mini Color Case Dimensions</a></li>
              </ul>
            </p>
            <p>MagBak Case for Samsung:</p>
            <p>
              <ul>
                <li><a target="_blank" href="/resources/pdf/MBC-24-Ultra-dimensions.pdf" title="Samsung S24 Ultra">Samsung S24 Ultra Case Dimensions</a></li>
                <li><a target="_blank" href="/resources/pdf/MBC-23-Ultra-dimensions.pdf" title="Samsung S23 Ultra">Samsung S23 Ultra Case Dimensions</a></li>
                <li><a target="_blank" href="/resources/pdf/MBC-22-Ultra-dimensions.pdf" title="Samsung S22 Ultra">Samsung S22 Ultra Case Dimensions</a></li>
                <li><a target="_blank" href="/resources/pdf/MBC-22-Plus-dimensions.pdf" title="Samsung S22 Plus">Samsung S22 Plus Case Dimensions</a></li>
              </ul>
            </p>
            <p>MagBak Case for Google Pixel:</p>
            <p>
              <ul>
                <li><a target="_blank" href="/resources/pdf/MBC-GP8-Pro-dimensions.pdf" title="Pixel 8 Pro">Pixel 8 Pro Case Dimensions</a></li>
                <li><a target="_blank" href="/resources/pdf/MBC-GP7-Pro-dimensions.pdf" title="Pixel 7 Pro">Pixel 7 Pro Case Dimensions</a></li>
                <li><a target="_blank" href="/resources/pdf/MBC-GP6-Pro-dimensions.pdf" title="Pixel 6 Pro">Pixel 6 Pro Case Dimensions</a></li>
              </ul>
            </p>
          `,
          url: "#case-dimensions"
        },
        */
      ]
    }
  ]
};



export const EC_FAQS_CATEGORY_INDEX = EC_FAQS.categories.map((category)=>{
  return {
    title: category.title,
    questions: category.questions.map((question)=>{
      let elem = question.answer
      // console.log("INNTER TEXT: ", elem);
      return {
        title: question.title,
        answer: question.answer.innerText
      }
    })
  };
});
