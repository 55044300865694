const Obj = require('./obj.js');
const _ = require('./underscore.js')._;

class Draft {

  constructor(data={}, sets={}, incs={}, clears={}) {
    this._data = data;
    this._sets = sets;
    this._incs = incs;
    this._clears = clears;
  }

  // Getters
  get data() { return this._data; }
  get sets() { return this._sets; }
  get incs() { return this._incs; }
  get clears() { return this._clears; }

  /*
   * Initializes the draft
  **/
  init(data) {
    this.reset();
  }

  /*
   * Resets the draft
  **/
  reset() {
    this._sets = {};
    this._incs = {};
    this._clears = {};
  }

  /**
   * Returns the list of updates
  **/
  get updates() {
    return { sets: this.sets, incs: this.incs, clears: this.clears };
  }

  /*
   * Get or update the given path value
  **/
  val(path, val, opts) {
    // Get all data
    if (path === undefined) {
      return this._data;
    }
    // Get Path data
    else if (val === undefined) {
      return Obj.dot(this._data, path);
    }
    // Set Path value
    else {
      this.set(path, val, opts);
    }
  }

  /*
   * Set the given path value
  **/
  set(path, val, {soft=false, force=false}={}) {
    let old = Obj.dot(this._data, path);
    if (old !== val || force) {
      let res = Obj.dot(this._data, path, val);
      if (!soft && res !== undefined) {

        // Check if this is a child change
        var parentPath = this._getParentEditPath(this._sets, path);
        if (parentPath) {
          let childPath = Obj.pathEnd(path, parentPath);
          Obj.dot(this._sets[parentPath], childPath, val);
        }
        else {
          this.purge(path);
          this._sets[path] = val;
        }
      }
    }
  }

  /*
   * Sets the value at the given path if nothing has been already set
  **/
  reg(path, val, opts) {
    let existing = this.val(path);
    if (existing === undefined) {
      this.val(path, val, opts);
      return val;
    }
    else {
      return existing;
    }
  }

  /*
   * Increments the given path value
  **/
  inc(path, val, {soft=false, force=false}={}) {
    let old = Obj.dot(this._data, path) || 0;
    let res = Obj.dot(this._data, path, old+val);
    if (!soft && res !== undefined) {
      // Get handle to old inc
      let oldInc = parseInt(this._incs[path]) || 0;

      // Check if this is a child of a Set change
      var parentPath = this._getParentEditPath(this._sets, path);
      if (parentPath) {
        let childPath = Obj.pathEnd(path, parentPath);
        Obj.dot(this._sets[parentPath], childPath, oldInc + val);
      }
      else {
        this.purge(path);
        this._incs[path] = oldInc + val;
      }
    }
  }

  /*
   * Clear the given path value
  **/
  clear(path, {soft=false, force=false}={}) {
    let old = Obj.dot(this._data, path) || 0;
    if (old !== undefined || force) {
      let res = Obj.purge(this._data, path);
      if ((!soft && res !== undefined) || force) {

        // Check if this is a child of a Set change
        var parentPath = this._getParentEditPath(this._sets, path);
        if (parentPath) {
          let childPath = Obj.pathEnd(path, parentPath);
          Obj.purge(this._sets[parentPath], childPath);
        }
        else {
          this.purge(path);
          this._clears[path] = true
        }
      }
    }

  }


  /*
   * Purges all of the updates
  **/
  purgeUpdates() {
    this._sets = {};
    this._incs = {} ;
    this._clears = {};
  }

  /*
   * Purges all the edits undet the given path
  **/
  purge(path) {
    this._purgeEdits(this._sets, path);
    this._purgeEdits(this._incs, path);
    this._purgeEdits(this._clears, path);
  }

  /*
   * Returns an array of changed paths
  **/
  setsList() { return Object.keys(this._sets);}
  incsList() { return Object.keys(this._incs);}
  clearsList() { return Object.keys(this._clears);}
  updatesList() {
    return _.flatten([this.setsList(),this.incsList(),this.clearsList()], true);
  }

  /*
   * Returns the differences between the original and new values
  **/
  newVals() {
    let vals = {};
    this.updatesList().forEach((path)=>{
      let val = this.val(path);
      Obj.dot(vals,path,val);
    });
    return vals;
  }

  /*
   * Helps purge all the edits under the given path
  **/
  _purgeEdits(edits, path) {
    delete edits[path];
    this._purgeChildEdits(edits, path);
    return edits;
  }

  /*
   * Helps purge all the child edits under the given path
  **/
  _purgeChildEdits(edits, path) {
    let editPaths = Object.keys(edits);
    editPaths.forEach((editPath)=>{
      if (Obj.isPathStart(editPath, path)) {
        delete edits[editPath];
      }
    });
    return edits;
  }

  /*
   * Helps get the path of the of the parent edit
  **/
  _getParentEditPath(edits, path) {
    let editPaths = Object.keys(edits);
    return editPaths.find((editPath)=>{
      return Obj.isPathStart(path, editPath);
    });
  }

  /*
   * Creates a dreaft from the given document
  **/
  static fromDoc(doc = {}) {
    return new this(doc.data, doc.sets, doc.incs, doc.clears);
  }


}
export default Draft;
