import * as ACTIONS from './ActionTypes';
import { bucketPostFile } from '../apis/AWS';
import { dot } from '../lib/obj'
import { fetch2 } from '../lib/fetch2'

const SUPPORT_ENDPOINT = process.env.REACT_APP_SUPPORT_ENDPOINT || "https://88k9ecl83c.execute-api.us-east-1.amazonaws.com/dev";

export const getOrderStatus = (opts={}) => {
  return ((dispatch, getState) => {
    // Dispatch action
    dispatch({
      type: ACTIONS.GET_ORDER_STATUS,
      opts: opts
    });

    // Get the data from the server
    return fetch2(`${SUPPORT_ENDPOINT}/status/orders/${opts.order}`,{
      queryParams: {email:opts.email}
    })
    .then((res)=>{
      res.json().then((json)=>{
        dispatch({
          type: ACTIONS.GET_ORDER_STATUS_SUCCESS,
          opts: opts,
          docs: json.docs
        });
        if (opts.onSuccess) { opts.onSuccess(json.docs, opts) }
      });
      return res;
    })
    .catch((err)=>{
      dispatch({
        type: ACTIONS.GET_ORDER_STATUS_ERROR,
        opts: opts,
        error: err
      });
      if (opts.onError) { opts.onError(err, opts) }
    });
  });
}
