import React from 'react';
import { connect } from 'react-redux'
import Form from './Form'
import Input from '../elements/Input';
import TextArea from '../elements/TextArea';
import RectButton from '../elements/RectButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { trim, isValidEmail } from '../../lib/formats';
import { dot } from '../../lib/obj';
import { _ } from '../../lib/underscore';
import { searchMatch} from '../../lib/search';

import './Form.css';
import './OrderForm.css';

import { PRODUCT_INDEX } from '../../constants/support/Products';

class OrderForm extends Form {

  onSubmit(evt) {
    let state = this.state;

    // Check fields and see if we need to abort submit
    if (!this.validateFields()) { return; }

    // Omit some fields from the ticket
    let ticket = _.omit(state,["errorField","shout","ticketState"]);
    ticket.type = this.ticketType();

    // Attempt to send the ticket
    this.sendTicket(ticket);
  }

  validateFields() {
    if (!this.namePasses()) { return false; }
    if (!this.emailPasses()) { return false; }
    if (!this.orderPasses()) { return false; }
    if (!this.productPasses()) { return false; }
    if (!this.reasonPasses()) { return false; }
    if (!this.productOrderedPasses()) { return false; }
    if (!this.productReceivedPasses()) { return false; }
    if (!this.productRequestPasses()) { return false; }
    if (!this.commentPasses()) { return false; }
    if (!this.filesPasses()) { return false; }
    if (!this.methodPasses()) { return false; }

    this.setState({ errorField: null, shout:null});
    return true;
  }

  ticketType() {
    return 'order-status';
  }

  namePasses() {
    if (this.refs.name && !trim(this.state.name)) {
      this.scrollToRef(this.refs.name);
      this.setState({
        errorField: "name",
        shout: "Please enter a full name."
      });
      return false;
    }
    return true;
  }

  emailPasses() {
    if (this.refs.email) {
      if (!trim(this.state.email)) {
        this.scrollToRef(this.refs.email);
        this.setState({
          errorField: "email",
          shout:"Please enter an email."
        });
        return false;
      }
      if (!isValidEmail(this.state.email)) {
        this.scrollToRef(this.refs.email);
        this.setState({
          errorField: "email",
          shout:"Please enter a valid email."
        });
        return false;
      }
    }
    return true;
  }

  orderPasses() {
    if (this.refs.order && !trim(this.state.order)) {
      this.scrollToRef(this.refs.order);
      this.setState({
        errorField: "order",
        shout:"Please enter an order #."
      });
      return false;
    }
    return true;
  }

  productPasses() {
    if (this.refs.product && !trim(this.state.product)) {
      this.scrollToRef(this.refs.product);
      this.setState({
        errorField: "product",
        shout:"Please enter a product."
      });
      return false;
    }
    return true;
  }

  productOrderedPasses() {
    if (this.refs.productOrdered && !trim(this.state.productOrdered)) {
      this.scrollToRef(this.refs.productOrdered);
      this.setState({
        errorField: "productOrdered",
        shout:"Please enter the ordered product."
      });
      return false;
    }
    return true;
  }

  productReceivedPasses() {
    if (this.refs.productReceived && !trim(this.state.productReceived)) {
      this.scrollToRef(this.refs.productReceived);
      this.setState({
        errorField: "productReceived",
        shout:"Please enter the received product."
      });
      return false;
    }
    return true;
  }

  productRequestPasses() {
    if (this.refs.productRequest && !trim(this.state.productRequest)) {
      this.scrollToRef(this.refs.productRequest);
      this.setState({
        errorField: "productRequest",
        shout:"Please enter the product request."
      });
      return false;
    }
    return true;
  }

  reasonPasses() {
    if (this.refs.reason && !trim(this.state.reason)) {
      this.scrollToRef(this.refs.reason);
      this.setState({
        errorField: "reason",
        shout:"Please select a reason."
      });
      return false;
    }
    return true;
  }

  commentPasses() {
    if (this.refs.comment && !trim(this.state.comment)) {
      this.scrollToRef(this.refs.comment);
      this.setState({
        errorField: "comment",
        shout:"Please write a comment."
      });
      return false;
    }
    return true;
  }

  filesPasses() {
    if (this.refs.files && !dot(this.state,"files.length") ) {
      this.scrollToRef(this.refs.files);
      this.setState({
        errorField: "files",
        shout:"Please add a picture or video."
      });
      return false;
    }
    return true;
  }

  methodPasses() {
    if (this.refs.method && !trim(this.state.method)) {
      this.scrollToRef(this.refs.method);
      this.setState({
        errorField: "method",
        shout:"Please select a shipping method."
      });
      return false;
    }
    return true;
  }

  onSuggestProduct(options, value) {
    let sugs = [];
    if (value && value.length > 0) {
      for (var i = 0; i < PRODUCT_INDEX.length; i++) {
        let prod = PRODUCT_INDEX[i];
        if (prod.options) {
          let kwds = prod.keywords.join(" ");
          prod.options.forEach((o)=>{
            let m = searchMatch(value,o+" "+kwds);
            if (m) { sugs.push(o); }
          });
        }
      }
    }
    return sugs;
  }

  // Check if ticket is being sent
  isSending() {
    let state = this.state;
    return state.ticketState && state.ticketState != "error" && state.ticketState != "bounced";
  }

  render() {
    let state = this.state;

    // Check the ticket status
    if (state.ticketState == "requested") {
      return (
        <div className="Form">
          <p>Thank you for contacting the MagBak Support Team!</p>
          <p>We have received your inquiry and will be getting back to you very soon.</p>
          <p>Our support hours are M-F 9AM-5PM US Pacific Time.</p>
          <p>We appreciate you choosing MagBak and look forward to resolving any concerns.</p>
          <p>Cheers!<br/>The MagBak Team</p>
          <FontAwesomeIcon className="Icon" icon={["fal","check-circle"]} />
        </div>
      );
    }
    else {

      // Check if there is an error
      let error = state.errorField != null || state.ticketState == "error";

      // Check if sending ticket
      let sending = this.isSending();

      // Put refs on fields that are required
      return (
        <div className="Form OrderForm">
          <Input ref="name" className="Name" label="Full Name *" value={this.state.name} error={state.errorField == 'name'} lock={sending} onChange={(value)=>{this.onEditField('name',value)}}/>
          <Input ref="email" className="Email" label="Email *" type="email" value={this.state.email}  error={state.errorField == 'email'} lock={sending} onChange={(value)=>{this.onEditField('email',value)}} onBlur={()=>{this.onBlurField('email')}}/>
          <Input className="Order" label="Order #" value={this.state.order} lock={sending} onChange={(value)=>{this.onEditField('order',value)}} onBlur={()=>{this.onBlurField('order')}}/>
          <TextArea ref="comment" className="Comment" label="Comment" value={this.state.comment} lock={sending} onChange={(value)=>{this.onEditField('comment',value)}}/>
          <RectButton className="Submit" theme="darkblue" working={sending} onClick={(evt)=>{this.onSubmit(evt)}}>{sending ? "WORKING" : "SUBMIT"}</RectButton>
          <p className="Shout" data-error={error}>{state.shout || "* Fields are required."}</p>
        </div>
      );
    }

  }
}

export default OrderForm;
