import React from 'react';
import Page from './Page'

import Separator from '../elements/Separator';
import CancelOrderForm from '../forms/CancelOrderForm';
import PageHeaderOutline from '../layouts/PageHeaderOutline';

import './Page.css';
import './CancelOrderPage.css';

class CancelOrderPage extends Page {
  render() {
    return (
      <div className="CancelOrderPage Page">
        <PageHeaderOutline title="Cancel Order" top={this._backButton()}></PageHeaderOutline>
        <p>Request to cancel an active order.</p>
        <CancelOrderForm/>
      </div>
    );
  }
}

export default CancelOrderPage;
