import React from 'react';
import ReactDOM from 'react-dom';

import { dot } from '../../lib/obj';
import { grip } from '../../lib/elem';
import { lssPost, lssGet } from '../../apis/Lss';

class Form extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      name: props.name,
      email: props.email,
      order: props.order,
      description: props.description
    };
  }

  onEditField(name,value) {
    this.setState({
      [name]:value,
      errorField: null,
      ticketState: null,
      shout: null
    },()=>{
      if (this.props.onEditField) { this.props.onEditField(name, value, this.state); }
    });
  }

  onBlurField(name) {
    if (this.props.onBlurField) { this.props.onBlurField(name,this.state); }
  }

  scrollToRef(ref) {
    if (ref) {
      let elem = ReactDOM.findDOMNode(ref);
      const y = elem.getBoundingClientRect().top + window.scrollY  - 100;
      window.scroll({
        top: y,
        behavior: 'smooth'
      });
    }
  }

  toText() {
    let lines = [];
    if(this.refs.root) {

      let elem = ReactDOM.findDOMNode(this.refs.root);
      // console.log(elem.children);
      for (var i = 0; i < elem.children.length; i++) {
        let child = elem.children[i];
        if (child.getAttribute("data-ignore")=="true") { continue; }
        let d = grip(child);
        switch (d.type) {
          case "p":
            lines.push("\n> "+d.text)
            break;
          case "Input":
          case "TextArea":
          case "SugControl":
          case "RadioControl":
          case "DropControl": {
            lines.push(`- ${d.label ? d.label+" " : ""}[ ${d.value} ]`);
            break;
          }
          case "AddressControl": {
            d.values.forEach((v, i) => {
              lines.push(`- ${v.label ? v.label+" " : ""}[ ${v.value} ]`);
            });
            break;
          }
          default:
            lines.push(d.value || d.label || d.text);
        }
      }
      // elem.children.forEach((child, i) => {
      //   console.log(child.nodeName);
      // });
    }
    return lines.join("\n");
  }

  // Attempts to send the ticket to the server
  sendTicket(ticket={}) {
    let state = this.state;

    // Set requesting state
    this.setState({
      ticketState: 'sending',
      shout: 'Sending ticket...'
    });

    // Create the ticket
    lssPost({
      path:'support/tickets',
      data: {
        ticket: ticket
      }
    }).then((res)=>{
      switch (res.status) {
        case 201:
          this.setState({
            ticketState: "confirming",
            shout: 'Checking email address...'
          });
          res.json().then((json)=>{
            console.log("JSON: ", json);
            this.monitorTicket(dot(json,'docs.ticket'));
          });
          break;
        case 400:
          this.setState({
            ticketState: "error",
            errorField: "email",
            shout: "Invalid email address. Please check it and try again."
          });
          break;
        default:
          this.setState({
            ticketState: "error",
            shout: "Unable to submit the support ticket at this time. Please try again later."
          });
      }
    });
  }

  // Monitos the active ticket
  monitorTicket(ticket) {
    // Clear old monitor (if any)
    this.stopTicketMonitor();

    if( ticket ) {
      // Start new monitor
      this._monitor = setInterval(async()=>{
        lssGet({
          path:`support/tickets/${ticket.loc}`
        }).then((res)=>{
          switch (res.status) {
            case 200:
              res.json().then((json)=>{
                this.handleTicketCheck(dot(json,'docs.ticket'));
              });
              break;
            default:
              this.stopTicketMonitor();
              this.setState({
                ticketState: "error",
                shout: "Unable to confirm the support ticket at this time. Please try again later."
              });
          }
        });
      },1000);

      // Clear the montir
      this._timeout = setTimeout(()=>{
        this.stopTicketMonitor();
        this.setState({
          ticketState: "error",
          shout: "Unable to confirm the support ticket at this time. Please try again later."
        });
      },30000);
    }
  }

  // Stops the ticket monitor
  stopTicketMonitor() {
    // Clear the monitor timeout
    if(this._timeout) {
      clearTimeout(this._timeout);
      this._timeout = null;
    }
    // Clear the monitor
    if(this._monitor) {
      clearInterval(this._monitor);
      this._monitor = null;
    }
  }

  // Handle thicket check
  handleTicketCheck(ticket) {
    // Simply return if monitor is no longer active
    if (!this._monitor) {return;}

    // Check the ticket state
    switch (dot(ticket,"state")) {
      case "requesting":
        this.setState({
          ticketState: "requesting",
          shout: "Creating the support ticket..."
        });
        break;
      case "requested":
        this.stopTicketMonitor();
        this.setState({
          ticketState: "requested",
          shout: "The support ticket has been created."
        });
        break;
      case "error":
        this.setState({
          ticketState: "error",
          shout: "Unable to create the support ticket at this time. Please try again later."
        });
        this.stopTicketMonitor();
        break;
      case "bounced":
        this.setState({
          ticketState: "bounced",
          errorField: "email",
          shout: "Invalid email address. Please check it and try again."
        });
        this.stopTicketMonitor();
        break;
    }
  }

  render() {
    return (
      <div>Hello form!</div>
    );
  }
}

export default Form;
