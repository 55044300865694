import { _ } from "./underscore";

/**
 * Gets or sets a nested value
 * NOTE: Updated to handle empty keys
 * NOTE: Updated to return final set value
**/
export const dot = function(obj, key, val, {build=true}={}) {
  // Validation
  if (obj === undefined || obj === null ) {
    return obj;
  }

  // Getting
  if (val === undefined) {
    let lev = obj;
    walk(key, (step) => {
      lev = lev[step];
      return lev !== undefined && lev !== null;
    });
    return lev;
  }
  // Setting
  else if (key !== undefined && key !== null && key !== "") {
    let lev = obj;
    walk(key, (step, left) => {
      if (left.length === 0) {
        lev[step] = val;
        val = lev[step];
      }
      else {
        let loc = lev[step];
        if (build && (loc === undefined || loc === null)) { lev[step] = {}; }
        lev = lev[step];
        return lev !== undefined && lev !== null;
      }
    });
    return val;
  }
};

/**
 * Walks the given dot path
 */
export const walk = function(path, cb) {
  if (cb && path != null) {
    let steps = Array.isArray(path) ? path : path.toString().split(".");
    for (var i = 0; i < steps.length; i++) {
      if ( cb(steps[i],steps.slice(i+1)) === false) {
        break;
      }
    }
  }
}

/**
 * Creates a deep copy of the given object
 * NOTE: Only supports plain ojects, arrays and native types
 */
export const deepCopy = function(obj) {
  return obj ? JSON.parse(JSON.stringify(obj)) : obj;
}


/*
 * Lists the items from the map in the given order provided
**/
export const arrange = (map={}, order)=>{
  let parts = []
  for (var i = 0; i < order.length; i++) {
    let step = order[i];
    let val = map[step];
    if (val !== undefined && val !== null) {
      parts.push(val);
    }
    else {
      break
    }
  }
  return parts;
}

/**
 * Registers the given value if it doesn't already exist in the object.
 * returns the resulting value under the given path
**/
export const reg = (obj, path, val) => {
  let existing = dot(obj,path);
  if (existing === undefined) {
    dot(obj,path, val);
    return val;
  }
  else {
    return existing;
  }
}

/**
 * Check if the path has the given start
**/
export const isPathStart = function(path, start) {
  if (path && start && path.length > start.length) {
    return path.indexOf(`${start}.`) === 0;
  }
  return false;
}


/**
 * Purges the nested value
**/
export const purge = function(obj, key) {
  // Validation
  if (obj === undefined || obj === null ) {
    return obj;
  }

  let lev = obj;
  let val = undefined;
  walk(key, (step, left) => {
    if (left.length === 0) {
      val = lev[step];
      delete lev[step]
    }
    else {
      let loc = lev[step];
      if (loc === undefined || loc === null) { lev[step] = {}; }
      lev = lev[step];
      return lev !== undefined && lev !== null;
    }
  });
  return val;
};


/**
 * Trakes the array and converts it to a hash
**/
export const mappify = function(arr, cb) {
  let map = {};
  if (!arr || !cb) { return map; }
  for (var i = 0; i < arr.length; i++) {
    let val = arr[i];
    let key = cb(val);
    map[key] = val;
  }
  return map;
};

/**
 * Returns a single level map consisting of keys of full dot paths.
 */
export const flatten = function(obj, path, comp={}) {
  // Exclude empty objects or strings
  if (!obj || _.isString(obj)) { return comp[path] = obj; }

  // Get the nested keys to see if dealing with container
  let keys = Object.keys(obj);
  if (keys && keys.length > 0) {
    for (var i = 0; i < keys.length; i++) {
      let key = keys[i];
      let nestedPath = path ? `${path}.${key}` : key;
      flatten(obj[key], nestedPath, comp);
    }
    return comp;
  }
  // Is edge
  else if (path !== undefined) {
    comp[path] = obj;
    return comp;
  }
  else {
    return obj;
  }
};
