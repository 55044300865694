import * as ACTIONS from '../actions/ActionTypes';
import { createReducer } from 'redux-starter-kit';
import { dot, reg } from '../lib/obj.js';


/*
 * Main Support reducer
 * NOTE: Uses Immer to more easily handle immutable state updates
 * Ref: https://redux-starter-kit.js.org/api/createreducer
**/
export const support = createReducer({}, {
  [ACTIONS.GET_ORDER_STATUS]: (state, action) => {
    dot(state, ["status","orders",action.opts.order],{
      status: "loading"
    });
  },
  [ACTIONS.GET_ORDER_STATUS_SUCCESS]: (state, action) => {
    dot(state, ["status","orders",action.opts.order], {
      status: !dot(action,"docs.status") ? "missing" : "loaded",
      data: dot(action,"docs.status")
    });
  },
  [ACTIONS.GET_ORDER_STATUS_ERROR]: (state, action) => {
    dot(state, ["status","orders",action.opts.order], {
      status: "error"
    });
  }
});
