import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Page from './Page'
import OrderForm from '../forms/OrderForm';
import OrderStatusForm from '../forms/OrderStatusForm';
import PageHeaderOutline from '../layouts/PageHeaderOutline';

import { getOrderStatus } from '../../actions/SupportActions.js';
import { openPopup, closePopup } from '../../actions/PopupActions.js';
import { dot } from '../../lib/obj';

import './Page.css';
import './OrderStatusPage.css';

class OrderStatusPage extends Page {

  onStatusResponse() {
    let data = this.props.form.data;
    this.props.openPopup({
      name:"OrderStatus",
      props: {
        order:data.order,
        onOk:(evt, history)=>{
          this.props.closePopup();
        }
      }
    });
  }

  onShoutError(errorField) {
    switch (errorField) {
      case "order":
        return <span>Please enter an order #. You can find your order number in your <b>MagBak Order</b> confirmation email.
        Check your Inbox and Spam folders. You can also <Link to="/order/status-request">Request order status here</Link>.
        </span>
        break;
      default:
        return null;
    }
  }

  render() {
    return (
      <div className="OrderStatusPage Page">
        <PageHeaderOutline title="Order Status" top={this._backButton()}></PageHeaderOutline>
        <p>Lookup order activity and shipping progress.</p>
        <OrderStatusForm onStatusResponse={()=>{this.onStatusResponse()}} shoutForError={(ef)=>this.onShoutError(ef)}/>
      </div>
    );
  }
}

const mapState = (state, props) => {
  let orderNumber = dot(state,"forms.status.data.order");
  return {
    form: dot(state,"forms.status") || {},
    order: dot(state,["support","status","orders",orderNumber]) || {}
  }
};

const mapDispatch = (dispatch) => {
  return {
    getOrderStatus: opts => dispatch(getOrderStatus(opts)),
    openPopup: opts => dispatch(openPopup(opts)),
    closePopup: opts => dispatch(closePopup(opts))
  }
};

export default connect(
  mapState,
  mapDispatch
)(OrderStatusPage)
