import React from 'react';
import ReactDOM from 'react-dom';
import Control from './Control';
import PlainButton from '../elements/PlainButton';
import './FileControl.css';
import { dot } from '../../lib/obj';
import { abbr } from '../../lib/formats';
import { lssPost, lssPut } from '../../apis/Lss';
import { awsPostS3File } from '../../apis/AWS';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Restrict file types
const ALLOWED_MIMES_MAP = {
  'application/pdf': true,
  'image/gif': true,
  'image/jpeg': true,
  'image/png': true,
  'text/csv': true,
  'text/plain': true,
  'video/mp4': true,
  'video/quicktime': true,
  'video/x-msvideo': true,
  'video/x-ms-wmv': true
};

// The max file size (25MB)
const MAX_FILE_SIZE = 25000000;

class FileControl extends Control {

  constructor(props) {
    super(props);
    this.state = {
      items: []
    };
  }

  chooseFile(evt) {
    let file = evt.target.files[0];
    if (file) {
      // Prepare item
      let item = {file:file, code: Date.now()};
      console.log("choseFile: ",item);

      // Fail if type is not allowed
      if (!ALLOWED_MIMES_MAP[file.type]) {
        item.message = "Type is not allowed.";
        item.status = "failed";
      }
      // Fail if size is too large
      else if (file.size > MAX_FILE_SIZE) {
        item.message = "File is too large.";
        item.status = "failed";
      }

      // Update state
      let items = [...this.state.items];
      items.push(item);
      this.setState({items:items});

      // Attempt to upload the item only if status is NOT Failed
      if (item.status != "failed") {
        this.uploadItem(item);
      }
    }
  }

  uploadItem(item) {
    let file = item.file;
    // Create the file doc and get upload authentication
    lssPost({
      path:'support/files',
      data: {
        file: {
          name: file.name,
          size: file.size,
          mime: file.type
        }
      }
    }).then((res)=>{
      switch (res.status) {
        case 201:
          res.json().then((json)=>{
            let fileDoc = dot(json,"docs.file");
            this.uploadFileAndProcess(item, fileDoc);
          });
          break;
        default:
          this.setItemStatus(item,"failed");
          console.log("ERROR file: ", item);
      }
    });
  }

  uploadFileAndProcess(item, fileDoc) {
    const loc = dot(fileDoc,"loc");
    this.setItemLoc(item, loc);
    let fileSize = 1;
    awsPostS3File({
      file: item.file,
      prereq: dot(fileDoc,"prereq"),
      onStart: ()=>{this.setItemStatus(item,"started")},
      onAbort: ()=>{this.setItemStatus(item,"aborted")},
      onError: ()=>{this.setItemStatus(item,"failed")},
      onLoad: ()=> {
        this.setItemStatus(item,"loaded");
        if (this.props.onItemsChanged) {
          this.props.onItemsChanged(this.state.items);
        }
        lssPut({
          path: `support/files/${loc}`,
          data: { file: { action: "process" } }
        }).then((res)=>{
          switch (res.status) {
            case 200:
            case 201:
              this.setItemStatus(item,"processed");
              break;
            default:
              this.setItemStatus(item,"failed");
              console.log("ERROR processing file: ", item);
          }
        });
      },
      onProgress: (evt)=>{ this.setItemProgress(item,evt.loaded, evt.total) }
    });
  }

  // Get the index for the given item
  indexOfItem(item) {
    // Search for corresponding item in state
    let index = null;
    let items = this.state.items;
    for (var i = 0; i < items.length; i++) {
      let curr = items[i];
      if (curr.code == item.code) {
        index = i;
        break;
      }
    }
    return index;
  }

  // Helps set the item locator
  setItemLoc(item, loc) {
    let items = this.state.items;

    // Abort if index is invalid
    let index = this.indexOfItem(item);
    if (index == null || !items[index] ) {return;}

    // Update the state
    items[index].loc = loc;
    this.setState({items: items});
  }

  // Helps update the item state
  setItemStatus(item, status) {
    let items = this.state.items;

    // Abort if index is invalid
    let index = this.indexOfItem(item);
    if (index == null || !items[index] ) {return;}

    // Update the state
    items[index].status = status;
    this.setState({items: items});
  }

  // Helps update the item state
  setItemProgress(item, loaded, total, scale=100) {
    let items = this.state.items;

    // Abort if index is invalid
    let index = this.indexOfItem(item);
    if (index == null || !items[index] ) {return;}

    // Update the progress
    items[index].loaded = loaded || 0;
    items[index].total = total || 1;
    items[index].progress = ((loaded/total) * scale);
    this.setState({items: items});
  }

  removeFile(evt, index) {
    let items = [...this.state.items];
    items.splice(index,1)
    this.setState({items:items});
    if (this.props.onItemsChanged) {
      this.props.onItemsChanged(this.state.items);
    }
  }

  render() {
    let className = this.props.className || "";
    return (
      <div className={`FileControl ${className}`} ref="root" data-error={this.props.error}>
        <div className="Items">
          {this.state.items.map((item, index)=>{
            let file = item.file;

            // Set the file message
            let message = item.message ? `- Error: ${item.message}` : `(${abbr(file.size,{type:'data',fix:1})})`;

            // Determine the icon
            return <div key={`item${index}`} className="Item" data-status={item.status}>
              <div className="Name"><FontAwesomeIcon icon={["fal","file"]} />{`${file.name} ${message}`}</div>
              <div className="Action" onClick={evt=>this.removeFile(evt,index)}><FontAwesomeIcon icon={["fal","times"]} /></div>
              <div className="Progress" style={{width:`${item.progress}%`}}></div>
            </div>
          })}
        </div>
        <div className="Add">
          <input type="file" name="+" onChange={(evt)=>this.chooseFile(evt)}/>
          <FontAwesomeIcon icon={["fal","paperclip"]} />Attach a file
        </div>
      </div>
    );
  }
}



export default FileControl
