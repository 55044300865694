import React from 'react';
import Page from './Page'

import PixBoxLayout from '../layouts/PixBoxLayout';
import Separator from '../elements/Separator';
import PixBoxMenu from '../menus/PixBoxMenu';
import PageHeaderOutline from '../layouts/PageHeaderOutline';

import './Page.css';
import './FaqPage.css';

import FAQLayout from '../layouts/FAQLayout';
import { PRODUCT_FAQS } from '../../constants/support/FAQs'
import { dot } from '../../lib/obj'

class FaqPage extends Page {

  onTestClick() {
    this.props.openPopup({ name:"Upload" });
  }

  render() {
    return (
      <div className="FaqPage Page">
        <PageHeaderOutline title={PRODUCT_FAQS.title} top={this._backButton()}></PageHeaderOutline>
        <p>{PRODUCT_FAQS.description}</p>
        <Separator></Separator>
        <FAQLayout faq={PRODUCT_FAQS} question={dot(this.props,"query.question")}/>
      </div>
    );
  }
}

export default FaqPage
