import React from 'react';
import ReactDOM from 'react-dom';
import Control from './Control';
import './DropControl.css';
import Input from '../elements/Input';
import Row from '../elements/Row';
import Readout from '../elements/Readout';
import RoundButton from '../elements/RoundButton';
import { dot } from '../../lib/obj';

class DropControl extends Control {

  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      focused: false,
    };
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  }

  handleClickOutside = event => {
    const domNode = ReactDOM.findDOMNode(this);
    if (!domNode || !domNode.contains(event.target)) {
      this.setState({focused: false});
    }
  }

  onClick() {
    this.setState({focused:!this.state.focused});
  }

  onOptionClick(option, index) {
    let value = option.name || option;
    this.setState({value:value, filter:value});
    if (this.props.onChange) {
      this.props.onChange(option);
    }
    if (this.props.onOptionClick) {
      this.props.onOptionClick(option, index);
    }
    this.setState({focused:!this.state.focused});
  }

  render() {

    // Handle to the options
    let options = this.state.options || this.props.options;
    let filter = this.state.filter === undefined ? this.props.value : this.state.filter;

    let value = this.props.value;
    let filled = value ? value.length > 0 : false;

    let className = this.props.className || "";
    return (
      <div className={`DropControl ${className}`} ref="root"
        data-focused={this.state.focused}
        data-layout={this.props.layout}
        data-theme={this.props.theme}
        data-filled={filled}
        data-error={this.props.error}
        onClick={(evt)=>{this.onClick(evt)}}>
        <div className="Label">{this.props.label}</div>
        <div className="Value">{value}</div>
        <div className="State">&#10095;</div>
        <div className="Options">
          {options.map((option, index)=>{
            let subnote = null;
            if (option.subnote) {
              subnote = <div className="Subnote">{option.subnote}</div>;
            }
            return (<div className="Option" key={`option${index}`} onClick={()=>{this.onOptionClick(option,index)}}>
              <div className="Entry">
                {option.name}
                {subnote}
              </div>
            </div>);
          })}
        </div>
      </div>
    );
  }
}



export default DropControl
