import React from 'react';

export const LEFT_MENU = [
  {
    icon: "📱",
    label: "Case",
    url: "/"
  },
  {
    icon: "💵",
    label: "Wallet",
    url: "/pos",
  },
  {
    icon: "🔋",
    label: "Charger",
    url: "/products"
  },
  {
    icon: "🌎",
    label: "MagStache",
    url: "/locations"
  },
  {
    icon: "🏭",
    label: "Support",
    url: "/support"
  },
];
