import React from 'react';
import { connect } from 'react-redux';
import Page from './Page';

import Separator from '../elements/Separator';
import ReplaceOrderForm from '../forms/ReplaceOrderForm';
import PageHeaderOutline from '../layouts/PageHeaderOutline';

import { getOrderStatus } from '../../actions/SupportActions.js';
import { openPopup, closePopup } from '../../actions/PopupActions.js';
import { dot } from '../../lib/obj';

import './Page.css';
import './ReplaceOrderPage.css';

class ReplaceOrderPage extends Page {
  render() {
    let form = this.props.form;
    return (
      <div className="ReplaceOrderPage Page">
        <PageHeaderOutline title="Replacement Request" top={this._backButton()}></PageHeaderOutline>
        <p>Request to replace a broken or defective product.</p>
        <ReplaceOrderForm email={dot(form,"data.email")} order={dot(form,"data.order")}/>
        <Separator/>
        <div className="Note">
          <a id="deliveryTimes"></a>
          <p>** Expected delivery times once items are shipped:</p>
          <ul>
            <li>USPS - Delivers within 12-15 business days.</li>
            <li>FedEx 2 Day - Delivers within 2 business days.</li>
            <li>FedEx Economy - Delivers within 5-7 business days.</li>
            <li>FedEx Express – Delivers within 2 business days with picture proof of delivery.</li>
            <li>FedEx International Priority - Delivers within 5-7 business days.</li>
          </ul>
        </div>
      </div>
    );
  }
}

const mapState = (state, props) => {
  return {
    form: dot(state, "forms.status")
  }
};

const mapDispatch = (dispatch) => {
  return {
    getOrderStatus: opts => dispatch(getOrderStatus(opts)),
    openPopup: opts => dispatch(openPopup(opts))
  }
};

export default connect(
  mapState,
  mapDispatch
)(ReplaceOrderPage)
