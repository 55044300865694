export const SHIP_METHODS = [
  {
    name: "USPS - Free (US only)",
    subnote: "Delivers within 12-15 business days **"
  },
  {
    name: "Fedex 2 Day - $4.50 (US only)",
    subnote: "Delivers within 2 business days **"
  },
  {
    name: "Fedex Priority - $13.00 (International)",
    subnote: "Delivers within 5-7 business days **"
  }
];
