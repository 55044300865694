import React from 'react';
import Page from './Page'

import Separator from '../elements/Separator';
import ExchangeOrderForm from '../forms/ExchangeOrderForm';
import PageHeaderOutline from '../layouts/PageHeaderOutline';

import './Page.css';
import './ExchangeOrderPage.css';

class ExchangeOrderPage extends Page {
  render() {
    return (
      <div className="ExchangeOrderPage Page">
        <PageHeaderOutline title="Exchange Request" top={this._backButton()}></PageHeaderOutline>
        <p>Request to exchange a product for a different one.</p>
        <ExchangeOrderForm/>
        <Separator/>
        <div className="Note">
          <a id="deliveryTimes"></a>
          <p>** Expected delivery times once items are shipped:</p>
          <ul>
            <li>USPS - Delivers within 12-15 business days.</li>
            <li>FedEx 2 Day - Delivers within 2 business days.</li>
            <li>FedEx Express – Delivers within 2 business days with picture proof of delivery.</li>
            <li>FedEx International Priority - Delivers within 5-7 business days.</li>
          </ul>
        </div>
      </div>
    );
  }
}

export default ExchangeOrderPage;
