import { _ } from "./underscore";
import { trim } from "./formats";

/*
 * Seach for the given terms in the string and decorate them
**/
export const searchMatch = function(term, str, {decorate=null, strict=true}={}) {
  term = trim(term);
  if (!term || !str) { return null }
  //term = term.replace(/(\+|\-|\)\()/g,"");
  term = term.replace(/[\W_]+/g," ");
  let terms = term.split(/\s+/).map((t)=>{return t.toLowerCase()});
  var regx = new RegExp(`(${terms.join('|')})`, "ig");
  let matches = {};
  str = str.replace(regx,(m)=>{
    matches[m.toLowerCase()] = 1;
    if (decorate) { m = decorate(m); }
    return m;
  });
  let matchCount = 0;
  terms.forEach((t)=>{matchCount+=(matches[t] || 0)});
  return (!strict && matchCount > 0) || (strict && matchCount == terms.length) ? str : null;
}

/*
 * Helps extract simple text from the given html
 * WARNING: This is an overly simplified way of extracting text from html.
**/
export const extractHTMLText = function(html) {
  if (!_.isString(html)) { return '' }
  return html.replace(/<[\s\S]*?>/g,' ');
}
