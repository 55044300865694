import React from 'react';
import ReactDOM from 'react-dom';
import Control from './Control';
import Input from '../elements/Input';
import SugControl from '../controls/SugControl';

import { dot } from '../../lib/obj';
import { searchMatch} from '../../lib/search';

import './AddressControl.css';

import { COUNTRIES } from '../../constants/misc/Countries'

class AddressControl extends Control {

  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      focused: false,
    };
  }

  onEditField(name, value) {
    if (this.props.onEditField) {
      this.props.onEditField(name,value);
    }
    this.setState({[name]:value});
  }

  onSuggestCountry(options, value) {
    let sugs = [];
    if (value && value.length > 0) {
      for (var i = 0; i < COUNTRIES.length; i++) {
        let entry = COUNTRIES[i];
        let cm = searchMatch(value,entry.code);
        let nm = searchMatch(value,entry.name);
        if (cm || nm) { sugs.push(entry.name); }
      }
    }
    return sugs;
  }

  render() {

    let state = this.state;

    let className = this.props.className || "";
    return (
      <div className={`AddressControl ${className}`} ref="root" data-ignore={this.props.ignore}>
        <Input className="Company" label="Company" value={state.company} onChange={(value)=>this.onEditField("company",value)}/>
        <Input className="Address1" label="Address" value={state.address1} onChange={(value)=>this.onEditField("address1",value)}/>
        <Input className="Address2" label="Apt, suite, etc." value={state.address2} onChange={(value)=>this.onEditField("address2",value)}/>
        <Input className="City" label="City" value={state.city} onChange={(value)=>this.onEditField("city",value)}/>
        <Input className="Province" label="State / Province" value={state.province} onChange={(value)=>this.onEditField("province",value)}/>
        <Input className="Zip" label="Zip / Postal Code" value={state.zip} onChange={(value)=>this.onEditField("zip",value)}/>
        <SugControl className="Country" label="Country" onSuggest={(options, value)=>this.onSuggestCountry(options,value)} onChange={(value)=>this.onEditField("country",value)}/>
      </div>
    );
  }
}



export default AddressControl
