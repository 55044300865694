import React from 'react';
import { connect } from 'react-redux';
import Page from './Page'

import Separator from '../elements/Separator';
import RefundOrderForm from '../forms/RefundOrderForm';
import PageHeaderOutline from '../layouts/PageHeaderOutline';

import { getOrderStatus } from '../../actions/SupportActions.js';
import { openPopup, closePopup } from '../../actions/PopupActions.js';
import { dot } from '../../lib/obj';

import './Page.css';
import './RefundOrderRequestPage.css';

class RefundOrderRequestPage extends Page {
  render() {
    let form = this.props.form;
    let order = this.props.order;
    return (
      <div className="RefundOrderPage Page">
        <PageHeaderOutline title="Refund Request" top={this._backButton()}></PageHeaderOutline>
        <p>Request a refund for a product or order.</p>
        <RefundOrderForm email={dot(form,"data.email")} order={dot(form,"data.order")} orderData={dot(order,"data")}/>
      </div>
    );
  }
}

const mapState = (state, props) => {
  let orderNumber = dot(state,"forms.status.data.order");
  return {
    form: dot(state,"forms.status") || {},
    order: dot(state,["support","status","orders",orderNumber]) || {}
  }
};

const mapDispatch = (dispatch) => {
  return {
    getOrderStatus: opts => dispatch(getOrderStatus(opts)),
    openPopup: opts => dispatch(openPopup(opts)),
    closePopup: opts => dispatch(closePopup(opts))
  }
};

export default connect(
  mapState,
  mapDispatch
)(RefundOrderRequestPage)
